import { Link } from "react-router-dom";
function Catering() {
    return (
      <>
      
  
        <section className="about padding-tb bg_size" style={{ backgroundImage: "url(assets/images/banner/catering.jpg)" }}>
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
          <div className="col-lg-6 col-12 ws">
              <div
                className="about-content"
                style={{
                  backgroundImage:
                    "linear-gradient(rgb(0 0 0) 0%, rgba(8, 31, 46, 0.24) 58%)",
                }}
              >
                <div className="section-header"></div>
                <div className="section-wrapper">
                  <form class="">
                    <input
                      type="text"
                      placeholder="Your Name"
                      name="username"
                    />
                    <input type="email" placeholder="Your Email" name="email" />
                    <input type="tel" placeholder="Your Mobile Number" name="tel" />
                    <span style={{ color: "red" }}></span>
                    <input
                      type="text"
                      placeholder="Prouct Name"
                      name="username"
                    />
                    <input
                      class="w-100"
                      type="text"
                      placeholder="Subject"
                      name="subject"
                    />
                 
            
                    <textarea
                      rows="8"
                      placeholder="Your Message"
                      name="message"
                    ></textarea>
                    <button class="food-btn style-2">
                      <span>Book Now</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="about-content" style={{ backgroundImage: "linear-gradient(rgb(0 0 0) 0%, rgba(8, 31, 46, 0.24) 58%)" }}>
                <div className="section-header">
                <span>BreadDough Hot & Fresh</span>
                  <h3>Catering services
                  </h3>
                </div>
                <div className="section-wrapper">
                Welcome to 'Breaddough Hot & Fresh' Catering Services – an immersive culinary journey where every detail is meticulously crafted to transform your events into unforgettable experiences. With a proud legacy of over four years delighting the New York Community, our catering services embody the essence of Afghan culture while seamlessly blending tradition with innovation.


                </div>

                <div className="section-wrapper">
                <b>1. Expertly Crafted Menus</b><br></br>

                <b>2.  Customization Tailored to You</b><br></br>
                <b>3. Uncompromising Quality</b><br></br>
                <b>4. Event Coordination Excellence</b><br></br>
                <b>5. Immerse in Afghan Tradition</b><br></br>
                <b>6. Book Your Culinary Extravaganza</b><br></br>

                </div>
                <div>
                <Link to="/shop" class="food-btn2"><span>ORDER NOW</span></Link>
                  
                </div>
                <div>
                <Link to="/menumob" class="food-btn2"><span>BROWSE MENU</span></Link>
                  
                </div>
                <div>
                <Link to="/contact-us" class="food-btn2"><span>BOOK CATERING</span></Link>
                  
                </div>

              </div>
            </div>


          </div>
        </div>
      </section>
      </>
    );
  }
  
  export default Catering;
  