import {useContext,useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/homecontext";
import Title from "../../pages/Title";
const p_slug = 'blog';
function BlogList(){
    const {isLoading,blog,fetchBlog,page,fetchPage} = useContext(AppContext);
    const {page_name,meta_title,meta_keyword,meta_description} = page;
    useEffect(()=>{
        fetchBlog("https://admin.breaddoughhotfresh.com/api/post/20");
        fetchPage(`https://admin.breaddoughhotfresh.com/api/pagelist/${p_slug}`);
    },[])
    if(isLoading)
      return <div>Loading.................</div>
    return(
<>
<Title meta_title={meta_title} meta_keyword={meta_keyword} meta_description={meta_description}/>
<section className="page-header style-2">
        <div className="container">
            <div className="page-title text-center">
                <h3>{page_name}</h3>
                <ul className="breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Blog</li>
                </ul>
            </div>
        </div>
    </section>
    <section className="blog-section blog-page padding-tb">
        <div className="container">
            <div className="section-wrapper">
                <div className="row justify-content-center">

                {blog && blog.map((data)=>{
                const {post_title,post_slug,post_short_desc,post_date,publisher_name,banner_image,banner_tag} = data;
                  return(  
                    <div className="col-lg-4 col-sm-6 col-12">
                        <div className="post-item">
                            <div className="post-item-inner">
                                <div className="post-thumb">
                                    <Link to={"/blog/"+post_slug}><img src={banner_image} alt={banner_tag}/></Link>
                                </div>
                                <div className="post-content">
                                    <h5><Link to={"/blog/"+post_slug}>{post_title}</Link>
                                    </h5>
                                    <div className="author-date">
                                        <Link to={"/blog/"+post_slug} className="date"><i className="icofont-calendar"></i>{post_date}</Link>
                                        <Link to={"/blog/"+post_slug} className="admin"><i className="icofont-ui-user"></i>{publisher_name}</Link>
                                    </div>
                                    <div dangerouslySetInnerHTML={{__html:post_short_desc}}/>
                                    <div className="post-footer">
                                        <Link to={"/blog/"+post_slug} className="text-btn">Read More<i
                                                className="icofont-double-right"></i></Link>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   )
                 })}   
                    
                    
                    
                    
                    
                    
                    
                </div>
                
            </div>
        </div>
    </section>
</>
    )
}

export default BlogList; 