import { useContext } from "react";
import { Link } from "react-router-dom";


function Cookies() {
  
  return (
    <>
      <section
        className="page-header style-2"
        style={{
          backgroundImage:
            'url("https://breaddough.kdassociate.us/public/upload/profile/1701335428nuts.jpg")',
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="page-title text-center">
            <h3>bakery/Cookies</h3>
            <ul className="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>bakery</li>
            </ul>
          </div>
        </div>
      </section>

     

      <div className="shop-page single padding-tb pb-0">
        <div className="container">
          <div className="section-wrapper">
            <div className="row justify-content-center">
              <div className="col-12">
                <article>
                  <div className="shop-single">
                    <div className="row justify-content-center">
                      <div className="col-md-4 col-12">
                        <div className="swiper-container gallery-top">
                          <div className="swiper-wrapper">
                            <div className="swiper-slide">
                              <div className="shop-item">
                                <div className="shop-thumb">
                                  <img
                                    src="assets/images/banner/cookies-box.jpg"
                                    alt="Limoncello Mascarpone Cake"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 col-12">
                        <div className="shop-single-content">
                          <h4>Get Your Mix & Match Cookies <br></br> Bucket </h4>
                          <div className="desc">
                            <div>
                              <p className="ppdfs">
                              Now, immerse yourself in the ultimate sweet experience with our exclusive 'Flavor Fusion Cookie Bucket' offer. Select from a diverse range of premium ingredients, including decadent chocolates, luscious fruits, and crunchy nuts, to design a batch that reflects your unique taste buds. Our commitment to freshly baked sweet delights ensures each mouthwatering bite captures the essence of Afghan tradition. Whether you fancy the classic charm of chocolate chip or the exotic allure of saffron-infused treats, the 'Flavor Fusion Cookie Bucket' is your ticket to a sweet adventure of self-expression. Indulge in the joy of creating delicious memories, where our culinary expertise meets your individual preferences. Join us at Bread Dough Hot & Fresh, where every cookie tells a story of tradition, quality, and the joy of savoring life's sweet moments.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="popular-chef-widget">
                          <div className="food-quyality">
                            <div className="section-wrapper">
                              <h4>$13.99/lb</h4>
                              
                                  <Link className="food-btn style-2" to="/product/get-your-mix-and-match-cookies-bucket" onClick={() => {window.scroll(0, 0);}}> <span>Order Now</span></Link> 
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="shop-page single padding-tb bg-fa">
        <div className="container">
          <div className="section-wrapper">
            <div className="shop-title d-flex flex-wrap justify-content-between">
              <p className="pd-tl">All Cookies</p>

            </div>
            <div className="shop-product-wrap grid row">
              <div className="col-xl-3 col-md-6 col-12 scl">
                <div className="product-item">
                  <div className="product-thumb bgm ">
                    <img
                      className="prd-main-img"
                      src="https://breaddough.kdassociate.us/public/upload/profile/1699873750roastef plain.jpg"
                      alt="Roasted Plain"
                    />
                  </div>
                  <div className="product-content">
                    <div className="product-title ttt">
                      <span className="cat-name">Bakery</span>
                      <h6>
                        <a href="#">Roasted Plain</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-12 scl">
                <div className="product-item">
                  <div className="product-thumb bgm ">
                    <img
                      className="prd-main-img"
                      src="https://breaddough.kdassociate.us/public/upload/profile/1699873718orange plain.jpg"
                      alt="Orange Plain"
                    />
                  </div>
                  <div className="product-content">
                    <div className="product-title ttt">
                      <span className="cat-name">Bakery</span>
                      <h6>
                        <a href="#">Orange Plain</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-12 scl">
                <div className="product-item">
                  <div className="product-thumb bgm">
                    <img
                      className="prd-main-img"
                      src="https://breaddough.kdassociate.us/public/upload/profile/1699873687strawberry cookie.jpg"
                      alt="Strawberry Plain"
                    />
                  </div>
                  <div className="product-content">
                    <div className="product-title ttt">
                      <span className="cat-name">Bakery</span>
                      <h6>
                        <a href="#">Strawberry Plain</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-12 scl">
                <div className="product-item">
                  <div className="product-thumb bgm ">
                    <img
                      className="prd-main-img"
                      src="https://breaddough.kdassociate.us/public/upload/profile/1699873727lemon cookie.jpg"
                      alt="Lemon"
                    />
                  </div>
                  <div className="product-content">
                    <div className="product-title ttt">
                      <span className="cat-name">Bakery</span>
                      <h6>
                        <a href="#">Lemon</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-12 scl">
                <div className="product-item">
                  <div className="product-thumb bgm ">
                    <img
                      className="prd-main-img"
                      src="https://breaddough.kdassociate.us/public/upload/profile/1699873660chocolate icing.jpg"
                      alt="Chocolate Icing"
                    />
                  </div>
                  <div className="product-content">
                    <div className="product-title ttt">
                      <span className="cat-name">Bakery</span>
                      <h6>
                        <a href="#">Chocolate Icing</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-12 scl">
                <div className="product-item">
                  <div className="product-thumb bgm ">
                    <img
                      className="prd-main-img"
                      src="https://breaddough.kdassociate.us/public/upload/profile/1699873652chocolate chip.jpg"
                      alt="Chocolate Chip"
                    />
                  </div>
                  <div className="product-content">
                    <div className="product-title ttt">
                      <span className="cat-name">Bakery</span>
                      <h6>
                        <a href="#">Chocolate Chip</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-12 scl">
                <div className="product-item">
                  <div className="product-thumb bgm ">
                    <img
                      className="prd-main-img"
                      src="https://breaddough.kdassociate.us/public/upload/profile/1699873642vanilla icing.jpg"
                      alt="Vanilla Icing"
                    />
                  </div>
                  <div className="product-content">
                    <div className="product-title ttt">
                      <span className="cat-name">Bakery</span>
                      <h6>
                        <a href="#">Vanilla Icing</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-12 scl">
                <div className="product-item">
                  <div className="product-thumb bgm ">
                    <img
                      className="prd-main-img"
                      src="https://breaddough.kdassociate.us/public/upload/profile/1702012075strawberry cookie.jpg"
                      alt="Strawberry (with Cardamom)"
                    />
                  </div>
                  <div className="product-content">
                    <div className="product-title ttt">
                      <span className="cat-name">Bakery</span>
                      <h6>
                        <a href="#">Strawberry (with Cardamom)</a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Cookies;
