import { createContext, useEffect, useReducer} from "react";
import reducer from "../reducer/homeReducer";
import axios from "axios";
const AppContext = createContext();
const initialState = {
    isLoading : false,
    isError : false,
    contact:[],
    logoData:[],
    products:[],
    category:[],
    blog:[],
    about:{},
    page:{},
};
const API = "https://admin.breaddoughhotfresh.com/api/productlist";
 const AppProvider = ({children}) =>{
    const [state,dispatch] = useReducer(reducer,initialState);
       // const [contact,setContact] = useState([]);
        const fetchContact = async() =>{
            dispatch({type:"API_LOADING"});
           try{ 
            const res = await axios.get("https://admin.breaddoughhotfresh.com/api/contact");
            const allData = await res.data;
           // setContact(allData);
           dispatch({type:"GET_CONTACT_DATA",payload:allData});
            //console.log(allData);
           }catch(error){
            dispatch({type:"API_ERROR"});
            console.log("my error",+error);
           } 
        }
    const fetchLogo = async(url) =>{
        dispatch({type:"API_LOADING"})
       try{ 
        const res = await axios.get(url);
        const logoData = await res.data;
        dispatch({type:"SET_LOGO",payload:logoData});
       }catch(error){
        dispatch({type:"API_ERROR"});
       }
    } 
    const fetchProducts = async(url) =>{
        dispatch({type:"API_LOADING"})
       try{ 
        const res = await axios.get(url);
        const productData = await res.data;
        dispatch({type:"SET_PRODUCT",payload:productData});
       }catch(error){
        dispatch({type:"API_ERROR"});
       }
    } 
    
    const fetchCategory = async(url) =>{
        dispatch({type:"API_LOADING"});
        try{
          const res =  await axios.get(url);
          const categoryData = await res.data;
          dispatch({type:"SET_CATEGORY",payload:categoryData});
        }catch(error){
            dispatch({type:"API_ERROR"});
        }
  }
const fetchBlog = async(url) =>{
    dispatch({type:"API_LOADING"});
    try{
      const res =  await axios.get(url);
      const blogData = await res.data;
      dispatch({type:"SET_BLOG",payload:blogData});
    }catch(error){
        dispatch({type:"API_ERROR"});
    }
}

const fetchAbout = async(url) =>{
    dispatch({type:"API_LOADING"});
    try{
      const res =  await axios.get(url);
      const pageData = await res.data;
      dispatch({type:"SET_ABOUT",payload:pageData});
    }catch(error){
        dispatch({type:"API_ERROR"});
    }
}
const fetchPage = async(url) =>{
    dispatch({type:"API_LOADING"});
    try{
      const res =  await axios.get(url);
      const pageData = await res.data;
      dispatch({type:"SET_PAGE",payload:pageData});
    }catch(error){
        dispatch({type:"API_ERROR"});
    }
}



    useEffect(()=>{
        fetchLogo("https://admin.breaddoughhotfresh.com/api/logo");
        fetchContact();
        fetchAbout("https://admin.breaddoughhotfresh.com/api/about");
        fetchCategory("https://admin.breaddoughhotfresh.com/api/productcategory");
        fetchProducts(API);
    },[]) 
    
    return(
        <AppContext.Provider value={{...state,fetchBlog,fetchPage}}>{children}</AppContext.Provider>
    )
 }

 export {AppProvider,AppContext} ;