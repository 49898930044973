import { Link } from "react-router-dom";
function Mealbox() {
  return (
    <>
      <section
        className="about padding-tb bg_size"
        style={{backgroundColor: "#1f1410"}}
      >
        <div className="container">
          <div className="row ">
            <div className="col-lg-5 col-12">
              <div className="about-thumb">
                {<img className="ab" src="assets/images/mb.png" alt="" />}
              </div>
            </div>
            <div className="col-lg-7 col-12">
              <div
                className="about-content"
                style={{
                  backgroundImage:
                    "linear-gradient(rgb(0 0 0) 0%, rgba(8, 31, 46, 0.24) 58%)",
                }}
              >
                <div className="section-header">
                  <h3>Get Your Mix & Match Meal Box</h3>
                  <span> 8 dishes all veg and all meaty</span>
                </div>
                <div className="section-wrapper">
                  Embark on a culinary journey with our 'Mix & Match Meal Box'
                  at Bread Dough Hot & Fresh. Customize your meal box by
                  selecting from 8 diverse veg and non-veg options, each
                  carefully crafted to capture the authentic Afghan flavors.
                  From delectable vegetarian choices to savory non-vegetarian
                  delights, our menu caters to every taste preference. Join us
                  in celebrating the pleasure of choice, where each bite
                  reflects our dedication to quality and the rich tapestry of
                  Afghan culinary traditions. Dive into a unique dining
                  experience, and let your palate be the guide in creating the
                  perfect blend of tradition and innovation.
                </div>

                <div className="section-wrapper">
                  <h4>Price - $8.99/Pound</h4>
                  <Link className="food-btn style-2" to="/product/get-your-mix-and-match-meal-box" onClick={() => {window.scroll(0, 0);}}> <span>Order Now</span></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-5 col-12">
              <div className="about-thumb">
                {<img className="ab" src="assets/images/banner/cookies-box.jpg" alt="" />}
              </div>
            </div>
            <div className="col-lg-7 col-12">
              <div
                className="about-content"
                style={{
                  backgroundImage:
                    "linear-gradient(rgb(0 0 0) 0%, rgba(8, 31, 46, 0.24) 58%)",
                }}
              >
                <div className="section-header">
                  <h3>Get Your Mix & Match Cookies Bucket</h3>
                </div>
                <div className="section-wrapper">
                Now, immerse yourself in the ultimate sweet
                              experience with our exclusive 'Flavor Fusion
                              Cookie Bucket' offer. Select from a diverse range
                              of premium ingredients, including decadent
                              chocolates, luscious fruits, and crunchy nuts, to
                              design a batch that reflects your unique taste
                              buds. Our commitment to freshly baked sweet
                              delights ensures each mouthwatering bite captures
                              the essence of Afghan tradition. Whether you fancy
                              the classic charm of chocolate chip or the exotic
                              allure of saffron-infused treats, the 'Flavor
                              Fusion Cookie Bucket' is your ticket to a sweet
                              adventure of self-expression. Indulge in the joy
                              of creating delicious memories, where our culinary
                              expertise meets your individual preferences. Join
                              us at Bread Dough Hot & Fresh, where every cookie
                              tells a story of tradition, quality, and the joy
                              of savoring life's sweet moments.
                </div>

                <div className="section-wrapper">
                  <h4>Price - $13.99/lb</h4>
                  <Link className="food-btn style-2" to="/product/get-your-mix-and-match-cookies-bucket" onClick={() => {window.scroll(0, 0);}}> <span>Order Now</span></Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-5 col-12">
              <div className="about-thumb">
                {<img className="ab" src="assets/images/banner/puffpastry.jpg" alt="" />}
              </div>
            </div>
            <div className="col-lg-7 col-12">
              <div
                className="about-content"
                style={{
                  backgroundImage:
                    "linear-gradient(rgb(0 0 0) 0%, rgba(8, 31, 46, 0.24) 58%)",
                }}
              >
                <div className="section-header">
                  <h3>Get Your Mix & Match Puff Pastry Bucket </h3>
                </div>
                <div className="section-wrapper">
                Welcome to 'Breaddough Hot & Fresh,' your gateway to
                      Afghan culinary delights in New York. Our Puff Pastry
                      Assortment Bucket offer lets you craft a personalized
                      experience, choosing from a delectable range of sweet and
                      savory pastries. With over four years of proudly serving
                      the community, our commitment to quality and tradition
                      shines through in every bite. Whether it's the flaky
                      layers of a baklava-inspired pastry or the savory
                      perfection of stuffed bolani, you're in control of your
                      taste journey. At our bakery, we're not just about food;
                      we're a celebration of Afghan culture. Join us on this
                      flavorful adventure that will leave you craving more.
                </div>

                <div className="section-wrapper">
                  <h4>Price - $13.99/lb</h4>
                  <Link className="food-btn style-2" to="/product/get-your-mix-and-match-puff-pastry-bucket" onClick={() => {window.scroll(0, 0);}}> <span>Order Now</span></Link>
                </div>
              </div>
            </div>
          </div>

        

    
        </div>
      </section>
    </>
  );
}

export default Mealbox;
