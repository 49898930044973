import { useState,useEffect } from "react";
import { Link ,useNavigate} from "react-router-dom";
import CheckoutSlider from "./CheckoutSlider";
import axios from "axios";
import { Helmet } from "react-helmet";
import validator from "validator";
function CheckOut({cart,setCart,setOrderId,userId}){
    const [price,setPrice] = useState(0);
    const [coupanCode,setCoupanCode] = useState('');
    const [discount,setDiscount] = useState(0);
    const [loader,setLoader] = useState(false);
    const [register,setRegister] = useState({
        "address":"","shipping":"","city":"","state":"","pincode":""
    });
    const [message, setMessage] = useState({"address":"","shipping":"","city":"","state":"","pincode":""});
    const navigate = useNavigate();
    const callPage = () => {
        navigate('/thankyou');
      };
      const callLogin = () => {
        navigate('/login');
      };
    if(userId === 0) {
        callLogin();        
    }

    const handlePrice = () => {
        let ans = 0;
        cart.map((item) => {
           ans += item.amount * parseFloat(item.product_price);
         })
        setPrice(ans);
      }    
    let name,value;

    const handlerInput = (e) =>{
    name = e.target.name;
    value = e.target.value;
    setRegister({...register,[name]:value});
    console.log(register);
   }
   const userRegister123 = async(event) =>{
    event.preventDefault(); 
        const {address,shipping,city,state,pincode} = register;
        if(address === null || address.trim().length ===0){
        setMessage({...message,"address":"Address required"});
        }else if(shipping === null || shipping.trim().length ===0){
            setMessage({...message,"shipping":"shipping address required"});
        }else if(city === null || city.trim().length === 0){
            setMessage({...message,"city":"City name required"});
        }else if(state === null || state.trim().length === 0){
            setMessage({...message,"state":"State name required"});
        }else if(pincode === null || pincode.trim().length === 0){
            setMessage({...message,"pincode":"pincode name required"});
        }else if(!validator.isNumeric(pincode)){
            setMessage({...message,"pincode":"invalid pin number"});
        }else{
        setLoader(true);    
        let res = await fetch("https://admin.breaddoughhotfresh.com/api/billingaddress",{
        method:"post",
        headers:{"Content-Type":"application/json"},
        body:JSON.stringify({
            address,shipping,city,state,pincode,userId
        })
    });
    setLoader(false);
    const data = await res.json();
        if(data.status){               
            // window.alert(data.msg); 
            handleOrder();       
        }else{
            window.alert(data.msg);           
        }
    }
    
}
     
   
 const handleOrder = async() =>{
    setLoader(true);
     const res1 = await fetch("https://admin.breaddoughhotfresh.com/api/addtocart",{ 
         method: 'POST',
         body: JSON.stringify({cart,price,userId,locationId,coupanCode,discount}),
         headers: {
              'Content-Type': 'application/json'
         }            
     })
     const data1 = await res1.json();
     setLoader(false);
     console.log(data1);
     if(data1.status){
        window.alert(data1.msg);
        setOrderId(data1.o_id); 
        setCart([]); 
        callPage();
     }else{
         window.alert(data1.msg);            
     }
 } 
 const [location, setLocation] = useState([]);
 const [locationId, setMyValue] = useState(0);
 const fetchLocation = async() =>{
  try{  
  const res = await axios.get("https://admin.breaddoughhotfresh.com/api/storelocation")
    const data = await res.data; 
         setLocation(data);
    }catch(error){
        console.log('location error',+error);
    }
  }
 const fetchUser = async() =>{
   try{ 
   const res = await axios.get("https://admin.breaddoughhotfresh.com/api/getaddress/"+userId)
      const data = await res.data ;
         setRegister(data);
   }catch(error){
    console.log('user error',error);
   }
       
   }
  useEffect(()=>{
    handlePrice();
    fetchLocation();
    fetchUser();
  },[]);
  const checkCoupan = async() =>{
    if(coupanCode != ''){
       const res = await axios.get("https://admin.breaddoughhotfresh.com/api/checkcoupan/"+coupanCode+"/"+userId)
       const data = await res.data;
        if(data.status){
            setDiscount(data.damount);
        }else{
            window.alert('Invalid Code');
            setCoupanCode('');
        }
    }      
  }
  if(loader) return <div>Wait............</div>
    return(
        <>
        <Helmet>       
        <title>CheckOut</title>
        <meta name='description' content="CheckOut" />
        <meta name="keywords" content="CheckOut"></meta>      
       </Helmet>
        <CheckoutSlider/>
        <section className="checkout-area pb-85">
        <div className="container">
            <form action="#">
                <div className="row">
                {userId === 0 && 
                    <div className="col-lg-6">                                                          
                    <div id="cbox_info" className="checkbox-form">
                    <Link to="/login" className="food-btn style-2"  onClick="">Login</Link><br/>
                    <Link to="/sign-up" className="food-btn style-2"  onClick="">Register</Link>
                    </div>
                    </div>
                }
                 {userId > 0 && 
                    <div className="col-lg-6">
                        <div className="checkbox-form">
                        <h2>Welcome : <span>{register.username}</span></h2> 
                            <h3>Billing Details</h3>
                            <div className="row">          
                                
                                <div className="col-md-12">
                                    <div className="checkout-form-list">
                                        <label>Address <span className="required">*</span></label>
                                        <input type="text" placeholder="Street address" name="address" value={register.address} onChange={handlerInput}/>
                                    </div>
                                    <span style={{"color":"red"}}>{message.address}</span>
                                </div>
                                <div className="col-md-12">
                                    <div className="checkout-form-list">
                                    <label>Shipping Address <span className="required">*</span></label>
                                        <input type="text" placeholder="shipping address" name="shipping" value={register.shipping} onChange={handlerInput}/>
                                    </div>
                                  <span>{message.shipping}</span>  
                                </div>
                                <div className="col-md-12">
                                    <div className="checkout-form-list">
                                        <label>Town / City <span className="required">*</span></label>
                                        <input type="text" placeholder="Town / City"  name="city" value={register.city} onChange={handlerInput}/>
                                    </div>
                                    <span>{message.city}</span> 
                                </div>
                                <div className="col-md-6">
                                    <div className="checkout-form-list">
                                        <label>State / County <span className="required">*</span></label>
                                        <input type="text" placeholder name="state" value={register.state} onChange={handlerInput}/>
                                    </div>
                                    <span>{message.state}</span> 
                                </div>
                                <div className="col-md-6">
                                    <div className="checkout-form-list">
                                        <label>Postcode / Zip <span className="required">*</span></label>
                                        <input type="text" placeholder="Postcode / Zip" name="pincode" value={register.pincode} onChange={handlerInput}/>
                                    </div>
                                    <span>{message.pincode}</span> 
                                </div>
                            <br/>
                       
                            <div class="order-button-payment mt-20">                   
                        {cart.length > 0 &&
                                <button class="food-btn style-2" style={{"width": "100%"}} onClick={userRegister123}><span>Place order</span></button>
                        } 
                            </div> 
                        
                            </div>
                         
                        </div>
                    </div>
               } 
                    <div className="col-lg-6">
                        <div className="your-order mb-30 ">
                            <h3>Your order</h3>
                            <div className="your-order-table table-responsive">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="product-name">Product</th>
                                            <th className="product-total">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {cart && cart.map((item,i) =>{   
                                      const {title,product_price,amount} = item;
                                      return(  
                                        <tr className="cart_item" key={i}>
                                            <td className="product-name">
                                               {title}<strong className="product-quantity"> × {amount}</strong>
                                            </td>
                                            <td className="product-total">
                                                <span className="amount">${amount*parseFloat(product_price)}</span>
                                            </td>
                                        </tr>
                                       )
                                      })}    
                                    </tbody>
                                    <tfoot>
                                        <tr className="cart-subtotal">
                                            <th>Cart Subtotal</th>
                                            <td><span className="amount">${price}</span></td>
                                        </tr>
                                        <tr className="shipping">
                                            <th>Shipping</th>
                                            <td>
                                                <ul>                                                    
                                                    <li>
                                                        <input type="radio" name="shipping" defaultChecked/>
                                                        <label>Free Shipping:</label>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        <tr><td><input type="text" className="form-control" onChange={(e) => setCoupanCode(e.target.value)}/></td><td><a href="#" onClick={()=>checkCoupan()}
                                                style={{"color":"red"}}>Apply</a></td></tr>
                                        <tr>
                                        <td>Store Location</td>
                                            <td>
                                                <select name='location'  onChange={(e) => setMyValue(e.target.value)}>
                                                   <option value="">....Select....</option>
                                                    {location && location.map(loc=>(
                                                        <option value={loc.s_id}>{loc.location_name}</option>
                                                    ))}
                                                </select>    
                                            </td>
                                        </tr>
                                       {discount > 0 &&
                                         <tr className="order-total">
                                         <th>Discount </th>
                                         <td><strong><span className="amount">${discount}</span></strong>
                                         </td>
                                         </tr>
                                       } 
                                        <tr className="order-total">
                                            <th>Order Total</th>
                                            <td><strong><span className="amount">${price-discount}</span></strong>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </section>
    </>
    )
}

export default CheckOut;