import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import validator from "validator";
import Title from "../pages/Title";
import { AppContext } from "../context/homecontext";
const p_slug = 'contact';
function Contact() {
    const [register, setRegister] = useState({
        "username": "", "email": "", "subject": "", "message": ""
    });
    const [msg, setMessage] = useState({ "email": "" });
    const [flag, setFlag] = useState(true);
    const { isLoading, contact, page, fetchPage } = useContext(AppContext);
    if (isLoading) <div>Loading........</div>
    const { t_number, c_number, email, addr_title, address } = contact;
    const { page_name, meta_title, meta_keyword, meta_description } = page;
    let name, value;
    const handlerInput = (e) => {
        name = e.target.name;
        value = e.target.value;
        setRegister({ ...register, [name]: value });
        console.log(register);
    }
    const userRegister = async (event) => {
        event.preventDefault();
        const { username, email, subject, message } = register;
        if (!validator.isEmail(email)) {
            setMessage({ "email": "Valid email id requered" });
            setFlag(false);
        } else {
            setMessage({ "email": "" });
            setFlag(true);
        }
        if (flag) {
            try {
                let res = await fetch("https://admin.breaddoughhotfresh.com/api/query", {
                    method: "post",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        username, email, subject, message
                    })
                });
                const data = await res.json();
                if (data.status) {
                    window.alert("Query submited");
                } else {
                    window.alert("error");
                }

            } catch (error) {
                console.error(error);
            }
        }
    }
    useEffect(() => {
        fetchPage(`https://admin.breaddoughhotfresh.com/api/pagelist/${p_slug}`);
    }, []);
    return (
        <>
            <Title meta_title={meta_title} meta_keyword={meta_keyword} meta_description={meta_description} />
            <section class="page-header style-2">
                <div class="container">
                    <div class="page-title text-center">
                        <h3>{page_name}</h3>
                        <ul class="breadcrumb">
                            <li><Link to="/">Home</Link></li>
                            <li>{page_name}</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="contact-information padding-tb pb-xl-0">
                <div class="container">
                    <div class="section-wrapper">
                        <div class="row">
                            <div class="col-lg-6 col-12">
                                <h5>Contact Information</h5>
                                <div class="post-item">
                                    <div class="post-content">
                                        <h6>{addr_title}</h6>
                                        <p>{address}</p>
                                    </div>
                                </div>
                                <div class="post-item">
                                    <div class="post-content">
                                        <h6>phone number : </h6>
                                        <p>+{t_number}, {c_number}</p>
                                    </div>
                                </div>
                                <div class="post-item">
                                    <div class="post-content">
                                        <h6>email adress : </h6>
                                        <p>{email}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-12">
                                <h5>Send Us A Message</h5>
                                <form class="d-flex flex-wrap justify-content-between">
                                    <input type="text" placeholder="Your Name" value={register.username} name="username" onChange={handlerInput} />
                                    <input type="text" placeholder="Your Email" value={register.email} name="email" onChange={handlerInput} />
                                    <span style={{ "color": "red" }}>{msg.email}</span>
                                    <input class="w-100" type="text" placeholder="Subject" name="subject" value={register.subject} onChange={handlerInput} />
                                    <textarea rows="8" placeholder="Your Message" name="message" onChange={handlerInput}>{register.message}</textarea>
                                    <button class="food-btn style-2" onClick={userRegister}><span>Submit Message</span></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Contact;