function Refund_Policy() {
    return (
      <>
      
  
      <section
  className="page-header style-2"
  style={{
    backgroundImage:
      'url("https://breaddough.kdassociate.us/public/upload/profile/1701335428nuts.jpg")',
    backgroundPosition: "center bottom",
    backgroundSize: "cover"
  }}
>
  <div className="container">
    <div className="page-title text-center">
      <h3>Refund Policy </h3>
      <ul className="breadcrumb">
        <li>
          <a href="/">Home</a>
        </li>
        <li>Refund Policy </li>
      </ul>
    </div>
  </div>
</section>


<div class="container ppppp">
   <div class="row">
      <h1>Refund Policy </h1>
      <>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Welcome to Breaddough Hot &amp; Fresh, where we strive to provide you with
      the finest and freshest Afghan culinary delights. Our commitment to your
      satisfaction extends to our Perishable Food Item Return Policy. We
      understand that despite our best efforts, situations may arise where you
      need assistance. This detailed policy is crafted to address your concerns
      and ensure a seamless experience with Breaddough Hot &amp; Fresh.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>1. Return Eligibility:</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      We accept returns and address quality-related issues for our perishable
      food items. To be eligible for a return, please contact us within 48 hours
      of receiving your order. Returns initiated outside of this timeframe may
      not be eligible for consideration.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>2. Quality Concerns:</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      If you encounter any issues with the quality of a perishable item, we
      encourage you to reach out to our customer service team promptly. Provide
      detailed information about the problem and, if possible, include images to
      help us better understand the issue. We are committed to addressing
      concerns related to freshness, taste, or any other quality-related
      matters.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        3. Refund, Replacement, or Store Credit:
      </span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Upon receiving the details of your concern, our dedicated team will assess
      the situation and determine the appropriate course of action. This may
      include:
    </span>
  </p>
  <ul>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Refund:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;If the issue warrants a refund, we will process it through the
          original method of payment. Please note that shipping costs are
          non-refundable.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Replacement:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;In certain cases, we may offer to replace the item at no
          additional cost to you. We want to ensure you receive the high-quality
          products you expect from Breaddough Hot &amp; Fresh.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Store Credit:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;Alternatively, we may issue store credit for the value of the
          affected item. This credit can be used for future purchases and is a
          token of our commitment to your satisfaction.
        </span>
      </p>
    </li>
  </ul>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>4. How to Initiate a Return:</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      To initiate a return for a perishable food item, please contact our
      customer service team at [contact email/phone number]. Provide your order
      number, details of the item(s) in question, and a clear description of the
      quality issue. Our team will guide you through the process and provide
      detailed instructions on where to send the item.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>5. Damaged or Defective Items:</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      If your perishable item arrives damaged or defective, please contact us
      immediately with comprehensive details and images of the issue. We
      understand the urgency in such cases and will work swiftly to assess the
      situation. Depending on the circumstances, we may offer a refund,
      replacement, or store credit to ensure your satisfaction.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>6. Customer Satisfaction:</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Your satisfaction is paramount to us. If you have any concerns or feedback
      regarding your purchase, please do not hesitate to reach out to our
      customer service team. We are dedicated to resolving issues promptly and
      ensuring that your experience with Breaddough Hot &amp; Fresh exceeds your
      expectations.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        7. Communication and Transparency:
      </span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      We value clear communication and transparency in our interactions with
      you. Throughout the return process, we will keep you informed of the
      progress, from the initiation of the return to the resolution of your
      concern. We are here to address your queries and provide any necessary
      assistance.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>8. Continuous Improvement:</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      At Breaddough Hot &amp; Fresh, we are committed to continuous improvement.
      Your feedback is invaluable, and we use it to enhance our processes and
      maintain the highest standards in delivering Afghan culinary delights to
      your doorstep.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      In conclusion, our Perishable Food Item Return Policy reflects our
      dedication to providing you with the freshest and highest quality
      products. Thank you for choosing Breaddough Hot &amp; Fresh, where we
      blend tradition, taste, and meticulous customer care in every delightful
      bite.
    </span>
  </p>
</>

</div>  </div> 
      </>
    );
  }
  
  export default Refund_Policy;
  