function Privacy_Policy() {
    return (
      <>
      
      <section
  className="page-header style-2"
  style={{
    backgroundImage:
      'url("https://breaddough.kdassociate.us/public/upload/profile/1701335428nuts.jpg")',
    backgroundPosition: "center bottom",
    backgroundSize: "cover"
  }}
>
  <div className="container">
    <div className="page-title text-center">
      <h3>Privacy Policy</h3>
      <ul className="breadcrumb">
        <li>
          <a href="/">Home</a>
        </li>
        <li>Privacy Policy</li>
      </ul>
    </div>
  </div>
</section>


<div class="container ppppp">
   <div class="row">
      <h1>Privacy Policy</h1>
      <>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Welcome to Breaddough Hot &amp; Fresh, your go-to destination for
      delectable Afghan treats in the heart of New York. As we celebrate over
      four years of serving the community with our passion for authentic Afghan
      flavors, we want to assure you that your privacy is of the utmost
      importance to us. This Privacy Policy outlines how we collect, use,
      disclose, and safeguard your personal information. By engaging with our
      website or services, you agree to the terms described herein.
    </span>
  </p>
  <ol>
    <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Information We Collect:</span>
        </strong>
      </p>
    </li>
  </ol>
  <p>
    <span style={{ fontSize: "11pt" }}>
      We collect various types of information to enhance your experience and
      improve our services. The information we may collect includes:
    </span>
  </p>
  <ul>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Personal Information:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;When you place an order or sign up for our loyalty program, we
          may collect your name, email address, phone number, and other
          necessary contact details.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Transaction Information:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;We collect details related to your purchases, including the
          products you buy, payment information, and delivery details.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Device Information:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;We may collect information about the device you use to access
          our website, including your IP address, browser type, and operating
          system.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Website Usage Data:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;Our website may use cookies and similar tracking technologies to
          gather information about your browsing activities, preferences, and
          interactions with our site.
        </span>
      </p>
    </li>
  </ul>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        Additional Information Collection:
      </span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;In addition to the mentioned categories, we may also collect
      information about your preferences, feedback, and interactions with our
      customer support for the purpose of continually improving our services and
      addressing customer needs.
    </span>
  </p>
  <ol start={2}>
    <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>How We Use Your Information:</span>
        </strong>
      </p>
    </li>
  </ol>
  <ul>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Order Fulfillment:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;We use your personal information to process and fulfill your
          orders, including delivery and payment processing.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Communication:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;We may use your contact details to send order confirmations,
          updates, and promotional offers. You can opt out of promotional
          communications at any time.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Improving Services:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;Analyzing website usage data helps us understand user
          preferences and enhance our website's functionality and user
          experience.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Marketing and Promotions:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;With your consent, we may use your information for marketing and
          promotional activities, keeping you informed about special offers and
          events.
        </span>
      </p>
    </li>
  </ul>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>In-depth Usage Explanation:</span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;We may use the collected information to personalize your experience,
      recommend products based on your preferences, conduct market research, and
      refine our product offerings to better meet your expectations.
    </span>
  </p>
  <ol start={3}>
    <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Data Security:</span>
        </strong>
      </p>
    </li>
  </ol>
  <p>
    <span style={{ fontSize: "11pt" }}>
      We take the security of your information seriously and implement measures
      to protect it from unauthorized access, disclosure, alteration, and
      destruction. Our website uses industry-standard encryption to safeguard
      your personal data during transmission.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>Enhanced Security Measures:</span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;Our security measures include regular security audits, employee
      training on data protection, and continuous updates to our security
      protocols to adapt to emerging threats and vulnerabilities.
    </span>
  </p>
  <ol start={4}>
    <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Third-Party Partners:</span>
        </strong>
      </p>
    </li>
  </ol>
  <p>
    <span style={{ fontSize: "11pt" }}>
      We may share your information with third-party service providers who
      assist us in delivering our services, such as payment processors and
      delivery partners. These third parties are obligated to protect your
      information and use it solely for the purpose of providing services to
      Breaddough Hot &amp; Fresh.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        Collaboration with Third Parties:
      </span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;We collaborate with trusted partners to ensure the efficiency of our
      services. Our agreements with these partners include strict privacy and
      confidentiality clauses.
    </span>
  </p>
  <ol start={5}>
    <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>
            Cookies and Tracking Technologies:
          </span>
        </strong>
      </p>
    </li>
  </ol>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Our website may use cookies and similar technologies to enhance your
      browsing experience. You have the option to disable cookies through your
      browser settings, but this may affect certain features of our website.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>Detailed Cookie Information:</span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;We utilize cookies for various purposes, including but not limited
      to session management, personalization, analytics, and advertising. By
      understanding how cookies function, you can make informed decisions about
      your privacy preferences.
    </span>
  </p>
  <ol start={6}>
    <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Your Choices:</span>
        </strong>
      </p>
    </li>
  </ol>
  <ul>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Opt-Out:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;You can opt out of receiving promotional communications from us
          by following the unsubscribe instructions included in each
          communication.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Access and Update:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;You have the right to access and update your personal
          information. Contact us if you need assistance in reviewing or
          correcting your information.
        </span>
      </p>
    </li>
    <li style={{ listStyleType: "disc", fontSize: "10pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Deletion:</span>
        </strong>
        <span style={{ fontSize: "11pt" }}>
          &nbsp;You can request the deletion of your personal information,
          subject to legal and contractual obligations.
        </span>
      </p>
    </li>
  </ul>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>Granular Control Options:</span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;We provide detailed controls in your account settings to manage your
      communication preferences, and we offer transparency about the data you've
      shared with us.
    </span>
  </p>
  <ol start={7}>
    <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Children's Privacy:</span>
        </strong>
      </p>
    </li>
  </ol>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Our services are not directed towards individuals under the age of 13. We
      do not knowingly collect personal information from children. If you are a
      parent or guardian and believe that your child has provided us with
      personal information, please contact us, and we will take appropriate
      steps to remove such information.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>Educational Initiatives:</span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;We are committed to educating parents and guardians about online
      privacy and encourage them to monitor and guide their children's online
      activities.
    </span>
  </p>
  <ol start={8}>
    <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Changes to Privacy Policy:</span>
        </strong>
      </p>
    </li>
  </ol>
  <p>
    <span style={{ fontSize: "11pt" }}>
      We reserve the right to update this Privacy Policy to reflect changes in
      our practices and services. Any updates will be posted on our website, and
      we encourage you to review this policy periodically.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>Notification of Changes:</span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;In the event of significant changes, we will notify you via email or
      prominently on our website, ensuring that you are informed about any
      modifications to the Privacy Policy.
    </span>
  </p>
  <ol start={9}>
    <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
      <p>
        <strong>
          <span style={{ fontSize: "11pt" }}>Contact Us:</span>
        </strong>
      </p>
    </li>
  </ol>
  <p>
    <span style={{ fontSize: "11pt" }}>
      If you have any questions, concerns, or requests regarding your privacy or
      this Privacy Policy, please contact us at [contact email/phone].
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>Dedicated Privacy Support:</span>
    </strong>
    <span style={{ fontSize: "11pt" }}>
      &nbsp;Our privacy support team is available to address any inquiries or
      concerns you may have regarding your personal information and privacy
      preferences.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Thank you for choosing Breaddough Hot &amp; Fresh. We appreciate your
      trust in us, and we remain committed to providing you with a delightful
      culinary experience while safeguarding your privacy.
    </span>
  </p>
</>

   </div>
</div>     

 </>
    );
  }
  
  export default Privacy_Policy;
  
