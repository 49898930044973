function Joinus() {
  return (
    <>
      <section
        className="page-header style-2"
        style={{
          backgroundImage:
            'url("https://breaddough.kdassociate.us/public/upload/profile/1701335428nuts.jpg")',
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="page-title text-center">
            <h3>Join Us</h3>
            <ul className="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Join Us</li>
            </ul>
          </div>
        </div>
      </section>

      <div className="cards ju">
        <div className="card fb cd">
          <i class="fa-brands fa-facebook-f"></i>
          <h2>Facebook</h2>
          <a
            class="foods-btn"
            href="https://www.facebook.com/profile.php?id=100089824064774"
            target="_blank"
          >
            <span>Join Now</span>
          </a>
        </div>
        <div className="card ig cd">
        <i class="fa-brands fa-instagram"></i>
                  <h2>Instagram</h2>
          <a
            class="foods-btn"
            href="#"
            target="_blank"
          >
            <span>Join Now</span>
          </a>
        </div>
        <div className="card yt cd">
        <i class="fa-brands fa-youtube"></i>          <h2>Youtube</h2>
          <a
            class="foods-btn"
            href="https://www.facebook.com/profile.php?id=100089824064774"
            target="_blank"
          >
            <span>Join Now</span>
          </a>
        </div>


      </div>
    </>
  );
}

export default Joinus;
