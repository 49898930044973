import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Banner from "./Banner";
import About from "./About";
import ShaopByCategory from "../shoap/ShoapByCategory";
import Tab from "./Tab";
import Testimonial from "./Testimonial";
import Faq from "./Faq";
import BookTable from "./BookTable";
import Blog from "./Blog";
import Title from "../../pages/Title";
import { AppContext } from "../../context/homecontext";
const p_slug = "home";
function Home({ handleClick }) {
  const { page, fetchPage, category, products } = useContext(AppContext);
  const { meta_title, meta_keyword, meta_description } = page;
  const comboproduct = products.filter((abc) => {
    return abc.offer_type == 2;
  });
  useEffect(() => {
    fetchPage(`https://admin.breaddoughhotfresh.com/api/pagelist/${p_slug}`);
  }, []);
  return (
    <>
      <Title
        meta_title={meta_title}
        meta_keyword={meta_keyword}
        meta_description={meta_description}
      />
      <Banner />

      <section
        className="about padding-tb bg_size"
        style={{ backgroundImage: "url(assets/images/bghome.jpg)" }}
      >
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-12">
              <div
                className="about-content"
                style={{
                  backgroundImage:
                    "linear-gradient(180deg, #1f1410 85%, #081F2E3D 150%)",
                }}
              >
                <div className="section-header">
                  <span>About Breaddough Hot & Fresh</span>
                  <h3>Proudly serving delectable halal-certified baked goods for over a year!</h3>
                </div>
                <div className="section-wrapper">
                <b>Our Commitment:</b>

  


                </div>
                <p><i class="fas fa-dot-circle" aria-hidden="true"></i> Quality Ingredients, Freshly Baked</p> 
               <p> <i class="fas fa-dot-circle" aria-hidden="true"></i> Halal Assurance Guaranteed
</p> 
               <p><i class="fas fa-dot-circle" aria-hidden="true"></i> Diverse Menu for Every Palate
</p> 
               <p> <i class="fas fa-dot-circle" aria-hidden="true"></i> Community Engagement & Support
</p> 
                <div className="section-wrapper">
                Join us for delicious treats that cater to your taste buds while adhering to your dietary preferences. Thank you for being a part of our journey. Visit us today and experience the warmth and flavor of Breaddough Hot & Fresh!{" "}
                </div>
                
                <div>
                <Link to="/menumob" class="food-btn2"><span>BROWSE MENU</span></Link>
                </div>
              
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="about-thumb">
                <img className="ab" src="assets/images/bghome1.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="about padding-tb bg_size"
        style={{ backgroundImage: "url(assets/images/banner/catering-home.jpg)" }}
      >
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-12">
              <div className="about-thumb">
                {/* <img className="ab" src="assets/images/ab.jpg" alt="" /> */}
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div
                className="about-content"
                style={{
                  backgroundImage:
                    "linear-gradient(rgb(0, 0, 0) 0%, rgb(0 0 0 / 65%) 58%)",
                }}
              >
                <div className="section-header">
               
                  <h3>Halal-Certified Catering by Breaddough Hot & Fresh.</h3>
                </div>
                <div className="section-wrapper">
                Introducing Breaddough Hot & Fresh Catering, your premier choice for halal-certified catering services!
                </div>

                <div className="section-wrapper">
                <b>Why Choose Us:</b>

  


                </div>
                <p><i class="fas fa-dot-circle" aria-hidden="true"></i> <b>Halal Certification: </b>Rest assured, our catering services adhere to strict halal standards, ensuring the highest quality and compliance with dietary requirements.</p> 
               <p> <i class="fas fa-dot-circle" aria-hidden="true"></i> <b>Customized Menus: </b>From corporate events to private gatherings, we offer a diverse range of halal-certified dishes tailored to your preferences and dietary needs.
</p> 
               <p><i class="fas fa-dot-circle" aria-hidden="true"></i> <b>Exceptional Quality: </b>Our commitment to using fresh, premium ingredients guarantees that every bite is bursting with flavor and satisfaction.
</p> 
               <p> <i class="fas fa-dot-circle" aria-hidden="true"></i> <b>Professional Service: </b>Our experienced team is dedicated to delivering seamless catering experiences, ensuring your event is a memorable success.
</p> 
               <p> <i class="fas fa-dot-circle" aria-hidden="true"></i> <b>Community Support: </b>By choosing BreadDough Hot & Fresh Catering, you support our mission of giving back to the community through our charitable initiatives.
</p> 
              
                <div className="section-wrapper">
                Contact us today to discuss your catering needs and experience the excellence of halal-certified cuisine!                </div>

                <div>
                <Link to="/catering" class="food-btn2"><span>ORDER CATERING</span></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product bg_size p-rel padding-tb">
        <div className="overlay" />
        <div className="container">
          <div className="section-header">
            <img
              src="assets/images/header/sc-img.png"
              alt="sc-img"
              className="header-img"
            />
            <span>Shop By Category</span>
            <h2>
              Indulge in the rich flavors of
              <br /> our Afghani Taste
            </h2>
          </div>
          <div className="section-wrapper pdcc">
            <div className="row">
              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                   
                  <Link to="/shop/restaurant">
                      <img src="assets/images/c1.jpg" alt="#" />
                      </Link>
                  </div>
                  <div className="product-content sp1">
                    <p>
                    <Link to="/shop/restaurant">Restaurant</Link>
                    </p>

                    <Link to="/shop/restaurant" className="cat-lk">
                      Order Now &gt;&gt;&gt;
                      </Link>
                  </div>
                </div>
              </div>
            
              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                  <Link to="/shop/bakery">
                      <img src="assets/images/c2.jpg" alt="#" />
                      </Link>
                  </div>
                  <div className="product-content sp1">
                    <p>
                      <a href="/shop/bakery">Bakery</a>
                    </p>

                    <Link to="/shop/bakery" className="cat-lk">
                      Order Now &gt;&gt;&gt;
                      </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                  <Link to="/shop/beverages">
                      <img src="assets/images/c3.jpg" alt="#" />
                      </Link>
                  </div>
                  <div className="product-content sp1">
                    <p>
                      <a href="/shop/beverages">Beverages</a>
                    </p>

                    <Link to="/shop/beverages" className="cat-lk">
                      Order Now &gt;&gt;&gt;
                      </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Tab category={category} products={products} handleClick={handleClick} />

      <section className="featured-item">
        <div className="container pf-mt">
          <div className="section-header">
            <img
              src="assets/images/header/sc-img.png"
              alt="sc-img"
              className="header-img"
            />
            <span>Foods Item</span>
            <h2>Today's Featured Item</h2>
          </div>
          <div className="row">
            {comboproduct.map((data) => {
              let url =
                "https://admin.breaddoughhotfresh.com/public/upload/profile/1699873334jalebi.jpg";
              if (data.photo != null) {
                url = data.photo;
              }
              return (
                <div class="col-md-6">
                  <div className="card bgcd  mt-4">
                    <img src={url} className="card-img-top" alt="..." />
                    <div className="card-body">
                      <div className="text-section">
                        <h5 className="card-title fw-bold">{data.title}</h5>
                        <div>${data.product_price}</div>
                         
                      </div>

                      <div className="cta-section">
                        <p className="card-text"></p>
                        <Link
                          to={"/product/" + data.product_code}
                          className="btn btn-dark"
                        >
                          <span>Order Now</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      
      <Testimonial />
      <Faq />
      <Blog />
    </>
  );
}
export default Home;
