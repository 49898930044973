import { Link } from "react-router-dom";
function Products() {
  return (
    <>
      <section class="page-header style-2">
        <div class="container">
          <div class="page-title text-center">
            <h3>Products</h3>
            <ul class="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Product</li>
            </ul>
          </div>
        </div>
      </section>

      <div className="single bg-fa">
  <div className="container">
    <div className="section-wrapper">
      <div className="shop-title d-flex flex-wrap justify-content-between">
        <p className="pd-tl">Product Category</p>
        <p>16 Results</p>
      </div>
      <div className="shop-product-wrap grid row">
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574906923 kofta.jpg"
                alt="Kofta"
              />
              <span className="price">$9.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/kofta">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Restaurant</span>
                <h6>
                  <a href="/product/kofta">Kofta</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574910950 CHICKEN KARAHI.jpg"
                alt="Chicken Karahi"
              />
              <span className="price">$12.49</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/chicken-karahi">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Restaurant</span>
                <h6>
                  <a href="/product/chicken-karahi">Chicken Karahi</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574864721 bolani.jpg"
                alt="Bulani"
              />
              <span className="price">$2.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/bulani">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Restaurant</span>
                <h6>
                  <a href="/product/bulani">Bulani</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574919020 chicken kebab.jpg"
                alt="Chicken Kabab"
              />
              <span className="price">$14.79</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/chicken-kabab">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Restaurant</span>
                <h6>
                  <a href="/product/chicken-kabab">Chicken Kabab</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/1699874145beef kebab.jpg"
                alt="Shami Kabob"
              />
              <span className="price">$14.79</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/shami-kabob">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Restaurant</span>
                <h6>
                  <a href="/product/shami-kabob">Shami Kabob</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574865418 boran banjan.jpg"
                alt="Burani Banjan"
              />
              <span className="price">$9.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/burani-banjan">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Restaurant</span>
                <h6>
                  <a href="/product/burani-banjan">Burani Banjan</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/1699874024sausage wrap.jpg"
                alt="Afghan Sausage Wrap chicken"
              />
              <span className="price">$7.49</span>
              <div className="product-action-link">
                <a
                  data-rel="lightcase"
                  href="/product/afghan-sausage-wrap-chicken"
                >
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Restaurant</span>
                <h6>
                  <a href="/product/afghan-sausage-wrap-chicken">
                    Afghan Sausage Wrap chicken
                  </a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574866515 ashaq.jpg"
                alt="Ashak"
              />
              <span className="price">$7.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/ashak">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Restaurant</span>
                <h6>
                  <a href="/product/ashak">Ashak</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      
        
      </div>
      <div className="paginations">
        <div className="container">
          <div className="row" />
        </div>
      </div>
    </div>
  </div>
</div>
<div className=" single bg-fa">
  <div className="container">
    <div className="section-wrapper">
     
      <div className="shop-product-wrap grid row">
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574880033 BAKLAVA WALNUT.jpg"
                alt="Baklava Walnut"
              />
              <span className="price">$13.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/baklava-walnut">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/product/baklava-walnut">Baklava Walnut</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574881031 craem roll.jpg"
                alt="Cream Roll"
              />
              <span className="price">$13.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/cream-roll">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/product/cream-roll">Cream Roll</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574883430 SOBIYET (WITH PISTACHIOS).jpg"
                alt="Sobiyet (with Pistachios)"
              />
              <span className="price">$13.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/sobiyet-with-pistachios">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/product/sobiyet-with-pistachios">
                    Sobiyet (with Pistachios)
                  </a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574884127 tulumba.jpg"
                alt="Tulumba"
              />
              <span className="price">$13.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/tulumba">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/product/tulumba">Tulumba</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574885029 SEKERPERA PISTACHIOS.jpg"
                alt="Sekerpera Pistachios"
              />
              <span className="price">$13.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/sekerpera-pistachios">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/product/sekerpera-pistachios">
                    Sekerpera Pistachios
                  </a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574891428 coconutdelight.jpg"
                alt="Coconut Delight"
              />
              <span className="price">$13.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/coconut-delight">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/product/coconut-delight">Coconut Delight</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574892526 pechaq.jpg"
                alt="Pechaq"
              />
              <span className="price">$13.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/pechaq">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/product/pechaq">Pechaq</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574894425 waraqi.jpg"
                alt="Waraqi 7 Layered Pastry"
              />
              <span className="price">$13.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/waraqi-7-layered-pastry">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/product/waraqi-7-layered-pastry">
                    Waraqi 7 Layered Pastry
                  </a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
    
      </div>
      <div className="paginations">
        <div className="container">
          <div className="row" />
        </div>
      </div>
    </div>
  </div>
</div>
<div className="single bg-fa">
  <div className="container">
    <div className="section-wrapper">
      
      <div className="shop-product-wrap grid row">
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574875018 banana smoothie.jpg"
                alt="Banana Smoothie"
              />
              <span className="price">$5.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/banana-smoothie">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Beverages</span>
                <h6>
                  <a href="/product/banana-smoothie">Banana Smoothie</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574875817 strawberry smoothie.jpg"
                alt="Strawberry Smoothie"
              />
              <span className="price">$5.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/strawberry-smoothie">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Beverages</span>
                <h6>
                  <a href="/product/strawberry-smoothie">Strawberry Smoothie</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574869416 coke.jpg"
                alt="Coca Cola"
              />
              <span className="price">$1.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/coca-cola">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Beverages</span>
                <h6>
                  <a href="/product/coca-cola">Coca Cola</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574870015 diet coke.jpg"
                alt="Diet Coke"
              />
              <span className="price">$1.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/diet-coke">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Beverages</span>
                <h6>
                  <a href="/product/diet-coke">Diet Coke</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574873013 pepsi.jpg"
                alt="Pepsi"
              />
              <span className="price">$1.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/pepsi">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Beverages</span>
                <h6>
                  <a href="/product/pepsi">Pepsi</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574873814 diet pepsi.jpg"
                alt="Diet Pepsi"
              />
              <span className="price">$1.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/diet-pepsi">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Beverages</span>
                <h6>
                  <a href="/product/diet-pepsi">Diet Pepsi</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574874412 ginger ale.jpg"
                alt="Genger Ale"
              />
              <span className="price">$1.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/genger-ale">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Beverages</span>
                <h6>
                  <a href="/product/genger-ale">Genger&nbsp;Ale</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="product-thumb">
              <img
                className="prd-main-img"
                src="https://admin.breaddoughhotfresh.com/public/upload/profile/170574929211 Ginseng.jpg"
                alt="Genseng"
              />
              <span className="price">$1.99</span>
              <div className="product-action-link">
                <a data-rel="lightcase" href="/product/genseng">
                  <i className="icofont-eye" />
                </a>
                <a href="javascript:void(0)">
                  <i className="icofont-cart-alt" />
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-title">
                <span className="cat-name">Beverages</span>
                <h6>
                  <a href="/product/genseng">Genseng</a>
                </h6>
                <a
                  href="javascript:void(0)"
                  className="food-btn style-2 atc-btn btn-pd"
                >
                  <span>Order Now</span>
                </a>
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <div className="paginations">
        <div className="container">
          <div className="row" />
        </div>
      </div>
    </div>
  </div>
</div>


    </>
  );
}

export default Products;
