import {useParams} from "react-router-dom";
import { useContext, useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/homecontext";
import Title from "../../pages/Title";
import axios from "axios";
const p_slug = 'product';
function ShoapSubCategory({handleClick}){
    const [submenu,setSubMenu] = useState({});
    const [currentPage,setCurrentPage] = useState(1);
    const {id,sub_id} = useParams();
    const {isLoading,products,page,fetchPage,category} = useContext(AppContext);
    const {meta_title,meta_keyword,meta_description} = page;
    let product = products.filter((abc)=>{
        return abc.slug === id && abc.sub_slug === sub_id;
    }); 
    const recordsPerPage = 25;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex-recordsPerPage;
    const records = product.slice(firstIndex,lastIndex);
    const nPage = Math.ceil(product.length/recordsPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1);
    useEffect(() =>{
        fetchPage(`https://admin.breaddoughhotfresh.com/api/pagelist/${p_slug}`);
    },[sub_id])

    const fetchsubCategory = async() =>{    
        try{
          const res =  await axios.get(`https://admin.breaddoughhotfresh.com/api/productsubcategory/${sub_id}`);
          const categoryData = await res.data;
          setSubMenu(categoryData);
          
        }catch(error){
            console.log("subcategory error",+error); 
        }
    }
    useEffect(()=>{
        fetchsubCategory();
    },[sub_id]);
    //console.log(products);
    if(isLoading){
        return(
            <div>Loading.............</div>
        )
    }
    const changeCPage = (n) =>{
        setCurrentPage(n);
     } 
     const prePage = () =>{
         if(currentPage !== 1){
            setCurrentPage(currentPage-1)
         }
     } 
     const nextPage = () =>{
        if(currentPage !== nPage){
            setCurrentPage(currentPage+1);
        }
     }     
     let temp = id.replace('-', ' '); 
     let sub = ''; 
     let catdata = category.filter((xyz)=>{
        return xyz.slug === id;
     }) 
          
    return(
      <>  
        <Title meta_title={meta_title} meta_keyword={meta_keyword} meta_description={meta_description}/>
        
         <section
         className="page-header style-2"
         style={{
           backgroundImage: "url("+submenu.subcategory_banner+")",
           backgroundPosition: "bottom center",
           backgroundSize: "cover" }}>
                   <div className="container">
                       <div className="page-title text-center">
                           <h3>{temp}/{submenu.sname}
                   </h3>
                           <ul className="breadcrumb">                    
                               <li><Link to="/">Home</Link></li>
                               <li>{temp}</li>
                           </ul>
                       </div>
                   </div>
               </section>
        
       <div className="shop-page single padding-tb bg-fa">
            <div className="container">
                <div className="section-wrapper">
                    <div className="shop-title d-flex flex-wrap justify-content-between">
                    <p class="pd-tl">
                            Product Category
                        </p>
                    </div>

                    <div className="shop-product-wrap grid row">
                   {records && records.map((product)=>{
                      //const {product_id,product_code,title,product_price,amount,photo,cname,sname} = product;
                        return(
                            <div className="col-xl-3 col-md-6 col-12 scl" >
                            <div className="product-item">
                                <div className="product-thumb">
                                    <img className="prd-main-img" src={product.photo} alt={product.title}/>
                                    <span className="price">${product.product_price}</span>
                                    <div className="product-action-link">
                                        <Link to={"/product/"+product.product_code} data-rel="lightcase"><i className="icofont-eye"></i></Link>
                                   
                                        <a href="javascript:void(0)" onClick={() => handleClick(product, 1)}><i className="icofont-cart-alt"></i></a>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <div className="product-title">
                                        <span className="cat-name">{product.cname}</span>
                                        <h6><Link to={"/product/"+product.product_code}>{product.title}</Link></h6>
                                      

                                        <a href="javascript:void(0)" class="food-btn style-2 atc-btn btn-pd"><span>Order Now</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                   })}
                         

                        
                    </div>
                    
                    <div className="paginations">

                    <div class="container">
                            <div class="row">
                                 
                                
                                
                            </div>
                        </div>
                
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default ShoapSubCategory;