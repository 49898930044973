import { useState } from "react";
import {Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import validator from "validator";
function SignUp({setUserId,cart}){
    const navigate = useNavigate();
    const callPage = () =>{
        navigate("/checkout");
    }
    const myaccount = () =>{
      navigate('/myaccount');
    }
    const [register,setRegister] = useState({
        "username":"","email":"","mobile":"","password":""
    });
    const [loader,setLoader] = useState(false);
    const [message, setMessage] = useState({"username":"","email":"","mobile":"","password":""});
    let name,value;
    const handlerInput = (e) =>{
      name = e.target.name;
      value = e.target.value;
      setRegister({...register,[name]:value});
      console.log(register);
     }
     const userRegister = async(event) =>{
      let flag = false;
      event.preventDefault();
      const {username,email,mobile,password} = register;
      if(username.trim().length ===0){
        setMessage({...message,"username":"User Name Requered"}); 
        flag = true;       
      }else if(!validator.isEmail(email)){
        setMessage({...message,"email":"Invalid email Id or Empty"});
        flag = true;       
    }else if(!validator.isMobilePhone(mobile)){
      setMessage({...message,"mobile":"Mobile number required"});
       flag = true;      
    }else if(password.trim().length === 0){    
        setMessage({...message,"password":"password empty"});
        flag = true;       
    }else{        
     try {
      
         //window.alert('hello');     
           setLoader(true);
            let res = await fetch("https://admin.breaddoughhotfresh.com/api/user_registration",{
            method:"post",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
            username,email,mobile,password
            })
         });
         const data = await res.json();
         setLoader(false);
            if(data.status){
            setUserId(data.lastid);   
               window.alert(data.msg);
               if(cart.length > 0){
                 callPage();
               }else{
                myaccount();
               }
            }else{
               window.alert(data.msg); 
            }
         
         }catch(error){
         console.error(error);
         }
      }
  }

  if(loader) return <div>Wait..................</div>
    return(
     <> 
      <Helmet>       
        <title>Sign Up</title>
        <meta name='description' content="Sign Up"/>
        <meta name="keywords" content="Sign Up"></meta>       
    </Helmet>
        <section className="signup__area po-rel-z1 pt-100 pb-100">
        <div className="container">
           <div className="row">
              <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                 <div className="sign__wrapper white-bg">                    
                    <div className="sign__form">
                       <form action="#">
                          <div className="sign__input-wrapper mb-25">
                             <h5>Full Name</h5>
                             <div className="sign__input">
                                <input type="text" placeholder="Full Name" name='username' value={register.username} onChange={handlerInput}/>
                                <i className="fa-regular fa-user"></i>
                                <span>{message.username}</span>
                             </div>
                          </div>
                          <div className="sign__input-wrapper mb-25">
                             <h5>Email Address</h5>
                             <div className="sign__input">
                                <input type="text" placeholder="E-mail Address" name='email' value={register.email} onChange={handlerInput}/>
                                <i className="fa-regular fa-envelope"></i>
                                <span>{message.email}</span>
                             </div>
                          </div>
                          <div className="sign__input-wrapper mb-25">
                             <h5>phone Number</h5>
                             <div className="sign__input">
                                <input type="text" placeholder="Phone number" name='mobile' value={register.mobile} onChange={handlerInput}/>
                                <i className="fa-solid fa-lock"></i>
                                <span>{message.mobile}</span>
                             </div>
                          </div>
                          <div className="sign__input-wrapper mb-10">
                             <h5>Password</h5>
                             <div className="sign__input">
                                <input type="password" placeholder="Password" name='password' value={register.password} onChange={handlerInput}/>
                                <i className="fa-solid fa-lock"></i>
                                <span>{message.password}</span>
                             </div>
                          </div>
                          
                          <button className="food-btn style-2" style={{"margin-top": "30px;"}} onClick={userRegister}> <span> Sign Up</span></button>
                          <div className="sign__new text-center mt-20">
                             <p>Already Have a Account? <Link to="/login"> Sign In</Link></p>
                          </div>
                       </form>
                    </div>
                 </div>
              </div>
           </div>
        </div>
    </section>
    </>
    )
}
export default SignUp;