import { Link } from "react-router-dom";
function Tab({category,products,handleClick}){
    const records = products.slice(1,9);
    return(
        
      <section className="product style-2 padding-tb" style={{ backgroundImage: "url(assets/css/bg-image/product-2.jpg)" }}>
      <div className="container">
          <div className="section-header">
              <img src="assets/images/header/sc-img.png" alt="sc-img" className="header-img" />
              <span>Foods Item</span>
              <h2 className="foodlist">Our Tasty Menu Today</h2>
          </div>
          <div className="tr-job-posted section-padding">
              <div className="container">
                  <div className="job-tab text-center">
  
                      <div className="tab-content text-left">
  
  
                          <div className="row no-gutters cstm-hp-tle">
                              {records && records.map((data)=>(
                              <div className="col-lg-3 col-12">
                                  <div className="product-item style-2">
                                      <div className="product-thumb">
                                          <img src={data.photo} alt={data.title} />
                                      </div>
                                      <div className="product-content">
                                          <div className="product-title">
                                              <h6>
                                                  <Link to={"/shop/"+data.product_code}>{data.title}</Link>
                                              </h6>
                                              <span className="price">${data.product_price}</span>
                                          </div>
                                          <div className="product-desc" />
                                          <div className="rating">
                                              

                                          <Link to={"/shop/"+data.product_code} className="foods-btn atc-btn">
                                                  <span>Order Now</span>
                                              </Link>

                                          </div>
                                      </div>
                                  </div>
                              </div>
  
                              ))}
  
  
                              <div className="section-header">
                                  <Link to="/shop" class="foods-btn style-2 vap" ><span>view All Product</span></Link>
                              </div>
                          </div>
  
                      </div>
                  </div>
  
              </div>
  
          </div>
      </div>
  </section>
    
    )
}

export default Tab;