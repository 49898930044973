function Wholesale() {
  return (
  <>
    <section className="about padding-tb bg_size wsss" style={{ backgroundImage: "url(assets/images/banner/wholesale.jpg)"
      }}>
      <div className="container">
        <div className="row align-items-center flex-row-reverse">
          <div className="col-lg-6 col-12 ws">
            <div className="about-content" style={{
              backgroundImage: "linear-gradient(rgb(0 0 0) 0%, rgba(8, 31, 46, 0.24) 58%)" , }}>
              <div className="section-header"></div>
              <div className="section-wrapper">
                <form class="">
                  <input type="text" placeholder="Your Name" name="username" />
                  <input type="email" placeholder="Your Email" name="email" />
                  <input type="tel" placeholder="Your Mobile Number" name="tel" />
                  <span style={{ color: "red" }}></span>
                  <input type="text" placeholder="Prouct Name" name="username" />
                  <input class="w-100" type="text" placeholder="Subject" name="subject" />
  
  
                  <textarea rows="8" placeholder="Your Message" name="message"></textarea>
                  <button class="food-btn style-2">
                    <span>Book Now</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <div className="about-content" style={{
              backgroundImage: "linear-gradient(rgb(0 0 0) 0%, rgba(8, 31, 46, 0.24) 58%)" , }}>
              <div className="section-header">
                <span> BreadDough Hot & Fresh</span>
                <h3>Bulk Orders, Halal Certified
                </h3>
              </div>
              <div className="section-wrapper">
                At BreadDough, we take pride in offering a wide array of halal-certified products for wholesale purposes.
                With a commitment to quality, freshness, and adherence to halal standards, we serve as your premier choice
                for wholesale baked goods.
              </div>
              <p><b>Our Promise:</b></p>
  
              <p><i class="fas fa-dot-circle" aria-hidden="true"></i><b> Halal-Certified Excellence</b> </p>
         
              <p><i class="fas fa-dot-circle" aria-hidden="true"></i><b> Variety and Customization </b> </p>
             
              <p><i class="fas fa-dot-circle" aria-hidden="true"></i><b> Consistency and Reliability</b> </p>
       
              <p><i class="fas fa-dot-circle" aria-hidden="true"></i><b> Exceptional Service</b> </p>
  
  
  
              <p><b>Why Choose BreadDough for Wholesale:</b></p>
  
     
              <p><i class="fas fa-dot-circle" aria-hidden="true"></i><b> Quality Ingredients</b> </p>
        
              <p><i class="fas fa-dot-circle" aria-hidden="true"></i><b> Competitive Pricing</b></p>
        
              <p><i class="fas fa-dot-circle" aria-hidden="true"></i><b> Flexible Ordering </b> </p>
        
              <p><i class="fas fa-dot-circle" aria-hidden="true"></i><b> Community-Oriented</b></p>
  
              <p>Contact us today to discuss your wholesale needs, and let us be your trusted partner in delivering
                quality and excellence to your customers. </p>
  
              <div className="section-wrapper">
                <a href="/shop" class="food-btn style-2">
                  <span>Show All Products</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  );
  }
  
  export default Wholesale;