import { useContext,useEffect } from "react";
import { Link } from "react-router-dom";

function Menumob({status}){

return(
<>
    <section class="page-header style-2">
        <div class="container">
            <div class="page-title text-center">
                <h3>Menu</h3>
                <ul class="breadcrumb">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>Menu</li>
                </ul>
            </div>
        </div>
    </section>

    <div className="container mbmnu">
        <div className="row">
            <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
                <div className="list-group list-group-flush">
                      <Link to="/shop/restaurant"><p className="mb-0 list-group-item text-uppercase font-weight-bold">
                                            Restaurant
                                          </p></Link>
                                         
                                        <Link to="/shop/restaurant/wraps-and-subs" className="list-group-item list-group-item-action">
                                        
                                         
                                          <img
                                            src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701072158Wraps.png"
                                            alt=""
                                          />
                                          Wraps & Subs
                                        </Link>
<Link to="/shop/restaurant/Vegetarian" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701072158Wraps.png" alt=""/>
vegetarian
</Link>

<Link to="/shop/restaurant/meaty" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701334503Meaty.png" alt=""/>
Meaty
</Link>
<Link to="/shop/restaurant/kabobs" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701334536Kabobs.png" alt=""/>
Kabobs
</Link>
                                         
                </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
                <div className="list-group list-group-flush">
                    <Link to="/shop/bakery"><p className="mb-0 list-group-item text-uppercase font-weight-bold">
                                            Bakery
                                          </p></Link>
                                          <Link to="/shop/bakery/bread" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701642265Naan.png" alt=""/>
Bread</Link>
<Link to="/cookies" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701335414Afghani-Kulcha-(cookies-with-nuts.png" alt=""/>
Cookies
</Link>
<Link to="/puffpastry" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701335546Puff-Pastry.jpg.png" alt=""/>
Puff Pastry
</Link>
<Link to="/shop/bakery/cake" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701335619Cake.png" alt=""/>
Cake
</Link>

               
                </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3 mb-md-0">
                <div className="list-group list-group-flush">
                    <Link to="/shop/beverages"><p className="mb-0 list-group-item text-uppercase font-weight-bold">
                                            Beverages
                                          </p></Link>
  
<Link to="/shop/beverages/smoothies" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701336030Smoothies.png" alt=""/>
Smoothies</Link>
<Link to="/shop/beverages/soda" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701336205Soda.png" alt=""/>
Soda</Link>
<Link to="/shop/beverages/energy-drinks" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701336278Energy-Drinks.png" alt=""/>
Energy Drinks</Link>




                </div>
            </div>

        </div>
    </div>


</>
)
}

export default Menumob;