import React,{useState,useEffect} from "react";
import { Link,useNavigate} from "react-router-dom";
import axios from "axios";
function MyCart({userId}){
    const [user,setUser] = useState({});
    const [order,setOrder] = useState([]);
    const [product,setProduct] = useState([]);
    const [product1,setProduct1] = useState({});
    const [status,setStatus] = useState(true);
    const navigate = useNavigate();
     if(userId===0){ navigate('/login')}
    const fetchUser = async() =>{
        try{ 
        const res = await axios.get("https://admin.breaddoughhotfresh.com/api/getaddress/"+userId)
           const data = await res.data ;
           setUser(data);
        }catch(error){
         console.log('user error',error);
        }            
    }
    const fetchOrder = async() =>{
        try{ 
        const res = await axios.get("https://admin.breaddoughhotfresh.com/api/orderlist/1")
           const data = await res.data ;
           setOrder(data);
        }catch(error){
         console.log('order error',error);
        }            
    }
    const viewlist = async(o_id) =>{
         setStatus(false);
         try{ 
            const res = await axios.get(`https://admin.breaddoughhotfresh.com/api/printoder/${userId}/${o_id}/1`)
               const data = await res.data ;
               setProduct(data['order']);
               setProduct1(data['coupan']);
            }catch(error){
             console.log('order error',error);
            }  
    }
    
    const goback = () =>{
        setStatus(true); 
    }
    useEffect(()=>{
        fetchUser();
        fetchOrder();
    },[]);
    const {username,mobile,shipping,city,state,pincode} = user;
    let total = 0; 
//if(order.length === 0) return <div>Welcome....</div>    
if(status){
    return(
        <>
        <section className="page-header style-2">
            <div className="container">
                <div className="page-title text-center">
                    <h3>My Account</h3>
                    <ul className="breadcrumb">
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>My Account</li>
                    </ul>
                </div>
            </div>
        </section>
    
        <div class="container cstm-acs">
            <table className="table table-striped table-bordered">
                <tr class="cstm-tr">
                    <td class="cstm-tl-h">User Name:</td>
                    <td class="cstm-br">{username}</td>
                    <td class="cstm-tl-h">Mobile:</td>
                    <td class="cstm-br">{mobile}</td>
                </tr>
                <tr class="cstm-tr">
                    <td class="cstm-tl-h">Shipping Address:</td>
                    <td class="cstm-br">{shipping}</td>
                    <td class="cstm-tl-h">City:</td>
                    <td class="cstm-br">{city}</td>
                </tr>
                <tr class="cstm-tr">
                    <td class="cstm-tl-h">State:</td>
                    <td class="cstm-br">{state}</td>
                    <td class="cstm-tl-h">Pincode:</td>
                    <td class="cstm-br">{pincode}</td>
                </tr>
    
    
            </table>
    
            <table className="table table-striped table-bordered trb-2">
    
    
                <tr class="cstm-tr-2">
                    <th class="cstm-tl-h">Sno.</th>
                    <th class="cstm-tl-h">Order No.</th>
                    <th class="cstm-tl-h">Amount</th>
                    <th class="cstm-tl-h">Status</th>
                    <th class="cstm-tl-h">Action</th>
                </tr>
                {order.map((list,k)=>{
                const {o_id,amount,status} = list;
                return(
                <tr>
                    <td>{k+1}</td>
                    <td>{o_id}</td>
                    <td>{amount}</td>
                    <td>{status ==0 && 'Waiting'}{status ==1 && 'Delivered'}{status ==2 && 'Cancel'}</td>
                    <td><button className="food-btn style-2" onClick={()=>viewlist(o_id)}><span>View</span> </button></td>
                </tr>
                )
                })}
    
            </table>
        </div>
    </>
    )
  }
  if(!status){
    return(
        <>
        <section className="page-header style-2">
        <div className="container">
            <div className="page-title text-center">
                <h3>My Account</h3>
                <ul className="breadcrumb">
                <li><Link to="/">Home</Link></li>
                    <li>My Account</li>
                </ul>
            </div>
        </div>
    </section>
    
    <div class="container cstm-acs">
    <table className="table table-striped table-bordered">
        <tr class="cstm-tr">
            <td class="cstm-tl-h">User Name:</td>
            <td class="cstm-br">{username}</td>
            <td class="cstm-tl-h">Mobile:</td>
            <td class="cstm-br">{mobile}</td>
        </tr>
        <tr class="cstm-tr">
            <td class="cstm-tl-h">Shipping Address:</td>
            <td class="cstm-br">{shipping}</td>
            <td class="cstm-tl-h">City:</td>
            <td class="cstm-br">{city}</td>
        </tr>
        <tr class="cstm-tr">
            <td class="cstm-tl-h">State:</td>
            <td class="cstm-br">{state}</td>
            <td class="cstm-tl-h">Pincode:</td>
            <td class="cstm-br">{pincode}</td>
        </tr>


    </table>

    <table className="table table-striped table-bordered trb-2">


        <tr class="cstm-tr-2">
            <th class="cstm-tl-h">Sno.</th>
            <th class="cstm-tl-h">Product</th>
            <th class="cstm-tl-h">Image</th>
            <th class="cstm-tl-h">Qty</th>
            <th class="cstm-tl-h">Price</th>
            <th class="cstm-tl-h">Sub Total</th>
        </tr>
        {product.map((list,k)=>{
            const {title,qty,price,photo} = list;
            total += qty*price;
            return(
            <tr>
                <td>{k+1}</td>
                <td>{title}</td>
                <td><img src={photo} style={{"width":"50px","height":"50px"}} alt="Product" /></td>
                <td>{qty}</td>
                <td>{price}</td>
                <td>{qty*price}</td>
            </tr>
            )
            })}
            <tr>
                <td calspan="4">Sub Total</td>
                <td>{total}</td>
            </tr>
            <tr>
                <td calspan="4">Discount</td>
                <td>{product1.discount}</td>
            </tr>
            <tr>
                <td calspan="4">Total</td>
                <td>{product1.amount}</td>
            </tr>

    </table>
</div>
        <button className="food-btn style-2" onClick={goback}>GoBack</button>
     </>   
    )
  }
}

export default MyCart;