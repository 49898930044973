
import { useState } from "react";
import {BrowserRouter,Route,Routes} from "react-router-dom";
import Header from './components/Header';
import Home from "./components/home/Home";
import Footer from "./components/Footer";
import { AppProvider } from "./context/homecontext";
import Shoap from "./components/shoap/ShoapList";
import ShoapCategoryList from "./components/shoap/ShoapCategoryList";
import ShoapSubCategory from "./components/shoap/ShoapSubCategory";
import Cart from "./components/shoap/Cart";
import Error from "./pages/Error";
import CheckOut from "./components/checkout/CheckOut";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import BlogList from "./components/blogs/BlogList";
import SingleBlog from "./components/blogs/SingleBlog";
import ThankYou from "./pages/ThankYou";
import SingleShoap from "./components/shoap/SingleShoap";
import PuffPastry from "./components/home/PuffPastry";
import Joinus from "./components/home/Joinus";
import Catering from "./components/home/Catering";
import Wholesale from "./components/home/Wholesale";
import Products from "./components/home/Products";
import Mealbox from "./components/home/Mealbox";
import Privacy_Policy from "./components/home/Privacy_Policy";
import Terms_And_Conditions from "./components/home/Terms_And_Conditions";
import Refund_Policy from "./components/home/Refund_Policy";
import Cookies from "./components/home/Cookies";
import About from "./components/home/About";
import Contact from "./components/Contact";
import LogOut from "./pages/LogOut";
import MyCart from "./components/mycart/MyCart";
import Menumob from "./components/Menumob";
import Pastry from "./components/Pastry";

function App(){
  const [cart,setCart] = useState([]);
  const [warning,setwarning] = useState(false);
  const [userId,setUserId] = useState(0);
  const [orderid,setOrderId] = useState();
  const handleClick = (item,q) =>{
    let isPresent = false;
    cart.forEach((product)=>{
      if(item.product_id ===product.product_id){
        isPresent = true;
        window.alert('This item is already added to cart');
      }      
    })
    if(isPresent){
      setwarning(false);
      setTimeout(()=>{
        setwarning(true);
      },2000);
      return;
    }
  window.alert(item.title+", Added to cart");  
  setCart([...cart,item]);    
}
    return (
        <> 
       
      <AppProvider> 
      <BrowserRouter>          
        <Header size={cart.length} cart={cart} setCart={setCart} userId={userId}/>
        <Routes>
            <Route path="/" element={<Home handleClick={handleClick}/>} exact></Route>
            <Route path="/shop" element={<Shoap handleClick={handleClick}/>} exact></Route>
            <Route path="/shop/:id" element={<ShoapCategoryList handleClick={handleClick}/>} exact></Route>
            <Route path="/shop/:id/:sub_id" element={<ShoapSubCategory handleClick={handleClick}/>} exact></Route>
            <Route path="/product/:id" element={<SingleShoap handleClick={handleClick}/>} exact></Route>
            <Route path="/puffpastry" element={<PuffPastry/>} exact></Route>
            <Route path="/cookies" element={<Cookies/>} exact></Route>
            <Route path="/joinus" element={<Joinus/>} exact></Route>
            <Route path="/catering" element={<Catering/>} exact></Route>
            <Route path="/mealbox" element={<Mealbox/>} exact></Route>
            <Route path="/wholesale" element={<Wholesale/>} exact></Route>
            <Route path="/products" element={<Products/>} exact></Route>
            <Route path="/privacy-policy" element={<Privacy_Policy/>} exact></Route>
            <Route path="/terms-conditions" element={<Terms_And_Conditions/>} exact></Route>
            <Route path="/refund-policy" element={<Refund_Policy/>} exact></Route>
            <Route path="/blog/" element={<BlogList/>} exact></Route>
            <Route path="about-us" element={<About/>} exact></Route>
            <Route path="contact-us" element={<Contact/>} exact></Route>
            <Route path="/blog/:id" element={<SingleBlog/>} exact></Route>
            <Route path="/cart" element={<Cart cart={cart} setCart={setCart}/>} exact></Route>
            <Route path="/checkout" element={<CheckOut cart={cart} userId={userId} setCart={setCart} setOrderId={setOrderId}/>} exact></Route>
            <Route path="/login" element={<Login setUserId={setUserId} cart={cart}/>} exact></Route>
            <Route path="/sign-up" element={<SignUp setUserId={setUserId} cart={cart}/>} exact></Route>
            <Route path="/thankyou" element={<ThankYou orderid={orderid}/>} exact></Route>
            <Route path="/myaccount" element={<MyCart userId={userId}/>} exact></Route>
            <Route exact={true} path='/logout' element={<LogOut setUserId={setUserId} userId={userId}/>}></Route>
            <Route path="/*" element={<Error/>}></Route>
            <Route path="menumob" element={<Menumob/>} exact></Route>
            <Route path="pastry" element={<Pastry/>} exact></Route>
           

        </Routes>
        <Footer/>
      </BrowserRouter>
      </AppProvider> 
       
        </>
       
    )
}

export default App;