import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/homecontext";
function Footer(){
    const {isLoading,contact,logoData,category} = useContext(AppContext); 
    const {t_number,email,address} = contact;
    const {logo,tag_name} = logoData;
    return(
        <>
        <footer
            className="padding-tb"
            style={{ backgroundImage: "url(assets/css/bg-image/footer-bg.jpg)" }}
        >
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12 col-xl-3">
                        <img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1693376255breaddough-logo.png" alt="logo" className={tag_name} />
                        <p className="ftr-txt">
                        Experience the vibrant flavors of Afghanistan in every bite.<br></br> Discover the fusion of tradition and taste!
                        </p>
                        <p>
                            <b>Follow Us : </b>
                        </p>
                        <ul className="follow_us">
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=100089824064774" target="_blank">
                                    <i className="fab fa-facebook" />
                                </a>
                            </li>
                            <li>
                                <a href="breaddough_hotfresh?igshid=OGQ5ZDc2ODk2ZA==" target="_blank">
                                    <i className="fab fa-instagram" />
                                </a>
                            </li>
                        
                        </ul>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xl-3 ftr-clm">
                        <div>
                            <h5>Quick Links</h5>
                            <ul>
                                <li>
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="/" onClick={() => {window.scroll(0, 0);}}> Home</Link>
                                    </p>
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="/about-us" onClick={() => {window.scroll(0, 0);}}> About Us</Link>
                                    </p>
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="/shop" onClick={() => {window.scroll(0, 0);}}> Product</Link>
                                    </p>
                                  
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="contact-us" onClick={() => {window.scroll(0, 0);}}> Contact Us</Link>
                                    </p>
                                  
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="privacy-policy" onClick={() => {window.scroll(0, 0);}}> Privacy Policy</Link>
                                    </p>
                                  
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="terms-conditions" onClick={() => {window.scroll(0, 0);}}> Terms & Conditions</Link>
                                    </p>
                                  
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="refund-policy" onClick={() => {window.scroll(0, 0);}}> Refund Policy</Link>
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xl-3 ftr-clm">
                        <div>
                            <h5>Shop By Category</h5>
                            <ul>
                                
                                <li>
                                <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="/shop/restaurant" onClick={() => {window.scroll(0, 0);}}> Restaurant</Link>
                                    </p>
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="/shop/bakery" onClick={() => {window.scroll(0, 0);}}> Bakery</Link>
                                    </p>
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="/shop/beverages" onClick={() => {window.scroll(0, 0);}}> Beverages</Link>
                                    </p>
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="/catering" onClick={() => {window.scroll(0, 0);}}> Catering</Link>
                                    </p>
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="/mealbox" onClick={() => {window.scroll(0, 0);}}> In The Box</Link>
                                    </p>
                                    <p>
                                        <i className="fas fa-dot-circle" />
                                        <Link to="/wholesale" onClick={() => {window.scroll(0, 0);}}> Wholesale </Link>
                                    </p>
                                
                              
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xl-3 ftr-clm-4">
                        <div className="contant-info">
                            <h5>Contact info</h5>
                            <ul className="info">
                                <li>
                                    <i className="fas fa-home" />
                                    <span>
                                       
3675 West Henrietta rd Suite 4 , Rochester, NY
                                    </span>
                                </li>
                                <li>
                                    <i className="fas fa-phone" />
                                    <span>
                                        <a href="tel:585 404 0072">585 404 0072</a>
                                    </span>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fas fa-globe-asia" />
                                        <span />
                                    </a>
                                    <a href="mailto:
Support@hfbreaddough.com">
                                       
Support@hfbreaddough.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <div className="fotter-header">
            <header>
                <div className="footer-bg">
                    <div className="container">
                        <div className="header-item">
                            <div className="header-logo cstm-ftr-btm">
                                <p>
                                    © <a href="#">Breaddough Hotefresh</a>, 2023. All Rights Reserved
                                </p>
                            </div>
                            <div className="header-logo cstm-ftr-btm">
                                <p>
                                    Developed and Design By
                                    <a href="http://onedizitalz.com/" target="_blank">
                                        Onedizitalz Inc
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* <div class="footer-bottom"></div> */}
        </div>

    </>
    )
}
export default Footer;