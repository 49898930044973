function Terms_And_Conditions() {
    return (
      <>
      
  
      <section
  className="page-header style-2"
  style={{
    backgroundImage:
      'url("https://breaddough.kdassociate.us/public/upload/profile/1701335428nuts.jpg")',
    backgroundPosition: "center bottom",
    backgroundSize: "cover"
  }}
>
  <div className="container">
    <div className="page-title text-center">
      <h3>Terms & Conditions</h3>
      <ul className="breadcrumb">
        <li>
          <a href="/">Home</a>
        </li>
        <li>Terms & Conditions</li>
      </ul>
    </div>
  </div>
</section>


<div class="container ppppp">
   <div class="row">
      <h1>Terms & Conditions</h1>
      <>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>1. Acceptance of Terms</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Welcome to Breaddough Hot &amp; Fresh! By accessing or using our website,
      mobile application, or any related services, you agree to comply with and
      be bound by the following terms and conditions. Please read these terms
      carefully before using our services. If you do not agree with these terms,
      you may not use our services.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>2. Use of the Website</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      You must be at least 18 years old to use Breaddough Hot &amp; Fresh
      services. By using our website or services, you confirm that you are at
      least 18 years old or are using the platform under the supervision of a
      parent or legal guardian.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>3. Intellectual Property</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      All content, trademarks, logos, and intellectual property displayed on our
      website are the property of Breaddough Hot &amp; Fresh. You may not use,
      reproduce, or distribute any content without our explicit permission.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>4. Privacy Policy</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Our Privacy Policy governs the use of your personal information. By using
      our services, you agree to the terms outlined in our Privacy Policy. We
      are committed to protecting your privacy and ensuring the security of your
      personal information.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>5. Order Placement</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      When placing an order with Breaddough Hot &amp; Fresh, you agree to
      provide accurate, current, and complete information. You are solely
      responsible for the accuracy of the information provided. We reserve the
      right to cancel or refuse any order at our discretion.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>6. Pricing and Payment</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      All prices on our website are in US dollars and inclusive of applicable
      taxes. We reserve the right to change prices at any time without prior
      notice. Payment is processed securely through our payment gateway. By
      providing your payment information, you authorize us to charge the
      specified amount.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>7. Delivery</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      We strive to deliver your orders promptly and efficiently. However,
      delivery times may vary based on your location and other factors.
      Breaddough Hot &amp; Fresh is not responsible for any delays or issues
      caused by external factors beyond our control.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>8. Quality Assurance</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      We take pride in delivering high-quality products that represent the
      authentic flavors of Afghanistan. If you are dissatisfied with your order,
      please contact us within 24 hours, and we will address your concerns
      promptly.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        9. Refund and Cancellation Policy
      </span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Refund requests will be considered on a case-by-case basis. If you wish to
      cancel your order, you must do so before it is dispatched. Refunds, if
      approved, will be processed through the original payment method.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>10. User Conduct</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      You agree not to use our services for any unlawful or prohibited purpose.
      This includes but is not limited to the transmission of any harmful or
      malicious content, interference with the proper functioning of our
      website, or any other activity that may violate applicable laws.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>11. Limitation of Liability</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Breaddough Hot &amp; Fresh shall not be liable for any direct, indirect,
      incidental, consequential, or punitive damages arising out of your use or
      inability to use our services. We do not guarantee that our services will
      be error-free or uninterrupted.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>
        12. Modifications to Terms and Conditions
      </span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Breaddough Hot &amp; Fresh reserves the right to modify these terms and
      conditions at any time. Changes will be effective immediately upon posting
      on the website. Your continued use of our services constitutes acceptance
      of the modified terms.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>13. Governing Law</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      These terms and conditions are governed by and construed in accordance
      with the laws of the State of New York. Any disputes arising out of or
      related to these terms shall be resolved in the state or federal courts
      located within the State of New York.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>14. Contact Information</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      If you have any questions or concerns about these terms and conditions,
      please contact us at contact@breaddoughhotandfresh.com.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>15. Indemnification</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      You agree to indemnify and hold harmless Breaddough Hot &amp; Fresh, its
      officers, directors, employees, and affiliates from any claims, damages,
      losses, liabilities, and expenses (including legal fees) arising out of or
      in connection with your use of our services or any breach of these terms.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>16. Dispute Resolution</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Any dispute arising from or relating to these terms and conditions shall
      be resolved through arbitration in accordance with the rules of the
      American Arbitration Association, and judgment upon the award rendered by
      the arbitrator(s) may be entered in any court having jurisdiction thereof.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>17. Force Majeure</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Breaddough Hot &amp; Fresh shall not be liable for any failure or delay in
      performing its obligations under these terms and conditions if such
      failure or delay is caused by events beyond our reasonable control,
      including but not limited to acts of God, war, terrorism, pandemics,
      government regulations, or natural disasters.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>18. Feedback and Reviews</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      We encourage you to provide feedback on our services. By submitting
      feedback or reviews, you grant Breaddough Hot &amp; Fresh the right to
      use, reproduce, and display such content on our website and marketing
      materials.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>19. Entire Agreement</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      These terms and conditions constitute the entire agreement between you and
      Breaddough Hot &amp; Fresh, superseding any prior agreements or
      understandings, whether written or oral, relating to the subject matter
      herein.
    </span>
  </p>
  <p>
    <strong>
      <span style={{ fontSize: "11pt" }}>20. Severability</span>
    </strong>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      If any provision of these terms and conditions is found to be invalid or
      unenforceable, the remaining provisions shall remain in full force and
      effect.
    </span>
  </p>
  <p>
    <span style={{ fontSize: "11pt" }}>
      Thank you for choosing Breaddough Hot &amp; Fresh. We appreciate your
      business and look forward to providing you with a delightful culinary
      experience!
    </span>
  </p>
</>

   </div>
</div>  
      </>
    );
  }
  
  export default Terms_And_Conditions;
  