import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/homecontext";
import Title from "../../pages/Title";
const p_slug = 'about-us';
function About({ status }) {
  const { isLoading, about, page, fetchPage } = useContext(AppContext);
  const { title, sub_title, description, photo, photo_tag } = about;
  const { short_description, long_description, meta_title, meta_keyword, meta_description } = page;
  useEffect(() => {
    fetchPage(`https://admin.breaddoughhotfresh.com/api/pagelist/${p_slug}`);
  }, []);
  if (isLoading) <div>Loading.........</div>
  return (
    <>
      <Title meta_title={meta_title} meta_keyword={meta_keyword} meta_description={meta_description} />
      {status !== "home" &&
        <section class="page-header style-2">
          <div class="container">
            <div class="page-title text-center">
              <h3>{title}</h3>
              <ul class="breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li>{title}</li>
              </ul>
            </div>
          </div>
        </section>
      }
      {/* <section className="about padding-tb">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-12">
              <div className="about-thumb">
                <img src={photo} alt={photo_tag} />
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="about-content">
                <div className="section-header">
                  <span>{title}</span>
                  <h3>{sub_title}</h3>
                </div>
                <div className="section-wrapper">
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                  {status !== "home" &&
                    <>
                      <div dangerouslySetInnerHTML={{ __html: short_description }} />
                      <div dangerouslySetInnerHTML={{ __html: long_description }} />
                    </>
                  }
                </div>

              </div>
              <a href="#" class="food-btn style-2"><span>Read More</span></a>
            </div>


          </div>
        </div>
      </section> */}

<section className="about padding-tb bg_size" style={{ backgroundImage: "url(assets/images/banner/about-banner.jpg)" }}>
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-12">
              <div className="about-thumb">
                {/* <img className="ab" src="assets/images/ab.jpg" alt="" /> */}
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="about-content" style={{ backgroundImage: "linear-gradient(rgb(0, 0, 0) 0%, rgb(0 0 0 / 65%) 58%)" }}>
                <div className="section-header">
                  <span>About Breaddough Hot & Fresh</span>
                  <h3>A World of Fast Food Choices
                  </h3>
                </div>
                <div className="section-wrapper">
                  Welcome to 'Breaddough Hot & Fresh,' where we have been proudly serving the New York Community for over four years. Our journey began with a deep-rooted passion for sharing the authentic flavors of Afghanistan with our beloved customers.


                </div>

                <div className="section-wrapper">
                  Our team brings their culinary expertise to every dish we serve. We take immense pride in our commitment to providing freshly baked sweet and savory delights, ensuring that each bite captures the essence of Afghan tradition.


                </div>
                <div>
                  <a href="/shop" class="food-btn2"><span>ORDER NOW</span></a>
                </div>
                <div>
                  <a href="/menumob" class="food-btn2"><span>BROWSE MENU</span></a>
                </div>
                <div>
                  <a href="/catering" class="food-btn2"><span>ORDER CATERING</span></a>
                </div>

              </div>
            </div>


          </div>
        </div>
      </section>
      <section className="product bg_size p-rel padding-tb">
        <div className="overlay" />
        <div className="container">
          <div className="section-header">
            <img
              src="assets/images/header/sc-img.png"
              alt="sc-img"
              className="header-img"
            />
            <span>Shop By Category</span>
            <h2>
              Indulge in the rich flavors of
              <br /> our Afghani Taste
            </h2>
          </div>
          <div className="section-wrapper pdcc">
          <div className="row">
              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                   
                  <Link to="/shop/restaurant">
                      <img src="assets/images/c1.jpg" alt="#" />
                      </Link>
                  </div>
                  <div className="product-content sp1">
                    <p>
                    <Link to="/shop/restaurant">Restaurant</Link>
                    </p>

                    <Link to="/shop/restaurant" className="cat-lk">
                      Order Now &gt;&gt;&gt;
                      </Link>
                  </div>
                </div>
              </div>
            
              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                  <Link to="/shop/bakery">
                      <img src="assets/images/c2.jpg" alt="#" />
                      </Link>
                  </div>
                  <div className="product-content sp1">
                    <p>
                      <a href="/shop/bakery">Bakery</a>
                    </p>

                    <Link to="/shop/bakery" className="cat-lk">
                      Order Now &gt;&gt;&gt;
                      </Link>
                  </div>
                </div>
              </div>

              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                  <Link to="/shop/beverages">
                      <img src="assets/images/c3.jpg" alt="#" />
                      </Link>
                  </div>
                  <div className="product-content sp1">
                    <p>
                      <a href="/shop/beverages">Beverages</a>
                    </p>

                    <Link to="/shop/beverages" className="cat-lk">
                      Order Now &gt;&gt;&gt;
                      </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About;