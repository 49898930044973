import { useState } from "react";
import {Link,useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function Login({setUserId,cart}){
    const [user, setUser] = useState({"email":"","password":""});
    const [loader,setLoader] = useState(false);
    const navigate = useNavigate();   
    const callPage = () =>{
        navigate('/checkout');
    }
    const myaccount = () =>{
      navigate('/myaccount');
    }    
    let name,value;
    const handlerInputs = (e) =>{
    name = e.target.name;
    value = e.target.value;
    setUser({...user,[name]:value});    
    console.log(user);
    }
      
const handleSubmit = async(event) => {     
        event.preventDefault();
    setLoader(true);    
    try {       
        const {email,password}  = user;             
        let res = await fetch("https://admin.breaddoughhotfresh.com/api/userLogin",{
            method:"post",
            headers:{"Content-Type":"application/json"},
            body:JSON.stringify({
                email,password
            })
        });
       const data = await res.json();
       setLoader(false);
       if(data.status){
        window.alert('user login successfull');
        setUserId(data.userId);
        if(cart.length > 0){
         callPage();
        }else{
         myaccount();
        }       
       }else{
        window.alert('user login failed');
       }      
       console.log(data);
    } catch (error) {
    // Handle any errors that occur during the fetch request
      console.error(error);
    }  
}
   if(loader) return <div>Wait..................</div>
    return(
      <>  
    <Helmet>       
        <title>Login</title>
        <meta name='description' content="Login" />
        <meta name="keywords" content="Login"></meta>      
    </Helmet>
        <section class="page-header style-2">
        <div class="container">
            <div class="page-title text-center">
                <h3>Login</h3>
                <ul class="breadcrumb">
                    <li><a href="index.html">Home</a></li>
                    <li>Login</li>
                </ul>
            </div>
        </div>
    </section>
        <section className="signin__area po-rel-z1 pt-100 pb-100">
        <div className="container">
           <div className="row">
              <div className="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
                 <div className="sign__wrapper white-bg">
                    
                    <div className="sign__form">
                       <form>
                          <div className="sign__input-wrapper mb-25">
                             <h5>Email Id</h5>
                             <div className="sign__input">
                                <input type="text" placeholder="E-mail Address" name="email" value={user.email} onChange={handlerInputs} required/>
                                <i className="fa-solid fa-envelope"></i>
                             </div>
                          </div>
                          <div className="sign__input-wrapper mb-10">
                             <h5>Password</h5>
                             <div className="sign__input">
                                <input type="password" placeholder="Password" name="password" value={user.password} onChange={handlerInputs} required/>
                                <i className="fa-solid fa-lock"></i>
                             </div>
                          </div>
                          <div className="sign__action d-sm-flex justify-content-between mb-30">
                             <div className="sign__agree d-flex align-items-center">
                                
                             </div>
                             <div className="sign__forgot">
                               {/* <a href="#">Forgot your password?</a>*/}
                             </div>
                          </div>
                          <button type="submit" className="food-btn style-2" onClick={handleSubmit}> <span> Sign In</span></button>
                          <div className="sign__new text-center mt-20">
                             <p>New Customer? <Link to="/sign-up">Sign Up</Link></p>
                          </div>
                       </form>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </section>
     </>
    )
}

export default Login;