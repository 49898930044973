import React, { useState, useEffect } from "react";
import axios from "axios";
function Testimonial() {
    const [testimonial, setTestimonial] = useState([]);
    const fetchTestimonial = async (url) => {
        try {
            const res = await axios.get(url);
            const data = await res.data;
            setTestimonial(data);
        } catch (error) {
            console.log('faq error', +error);
        }
    }
    useEffect(() => {
        fetchTestimonial('https://admin.breaddoughhotfresh.com/api/testimonial');
    }, [])
    return (
        <section class="clients-area padding-tb bg_size p-rel"
            style={{ backgroundImage: "url(assets/images/clients/clients_bg.jpg);" }}>
            <div class="overlay"></div>
            <div class="container">
                <div class="section-header text-center">
                    <img src="assets/images/header/sc-img.png" alt="sc-img" class="header-img" />
                    <span>Our Valuable Client</span>
                    <h2>What People Say About us</h2>
                </div>
                <section className="home-testimonial">
                    <div className="container-fluid">

                        <section className="home-testimonial-bottom">
                            <div className="container testimonial-inner">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-4 style-3">
                                        <div className="tour-item ">
                                            <div className="tour-desc bg-black">
                                                <div className="tour-text color-grey-3 text-center">
                                                    “Breaddough is a new fixture in Henrietta (opened in February 2023) that you’ll definitely want to visit! Currently they offer unmatched fresh breads, Afghan specialties, and all the varieties of desserts you can imagine.”
                                                </div>
                                                
                                                <div className="link-name d-flex justify-content-center">
                                                   <b>Bridget Querns</b>  
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 style-3">
                                        <div className="tour-item ">
                                            <div className="tour-desc bg-black">
                                                <div className="tour-text color-grey-3 text-center">
                                                    “
                                                    Delicious and truly handmade pastries. Highly recommend. One can order a box of cookies as well as make large party size order. Service is quick and friendly. They also have other options in a very nice environment. The smell of fresh baked goods is to dream for.
                                                    ”
                                                </div>
                                                
                                                <div className="link-name d-flex justify-content-center">
                                                   <b>Cris Guzman</b>  
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 style-3">
                                        <div className="tour-item ">
                                            <div className="tour-desc bg-black">
                                                <div className="tour-text color-grey-3 text-center">
                                                    “This place is incredible! They have both savory and sweet delicious treats. Everything I had was fresh and made my taste buds dance. Give them a try and I promise you will be back!! Plenty of parking and very clean inside.”
                                                </div>
                                               
                                                <div className="link-name d-flex justify-content-center">
                                                <b>Lisa Micciche</b> 
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </section>

            </div>
        </section>
    )
}
export default Testimonial;