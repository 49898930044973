function PuffPastry() {
  return (
    <>
      <section
        className="page-header style-2"
        style={{
          backgroundImage:
            'url("https://breaddough.kdassociate.us/public/upload/profile/1701335546Puff-Pastry.jpg")',
          backgroundPosition: "center bottom",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="page-title text-center">
            <h3>bakery/Puff Pastry</h3>
            <ul className="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>bakery</li>
            </ul>
          </div>
        </div>
      </section>
      

      <div className="shop-page single padding-tb pb-0">
  <div className="container">
    <div className="section-wrapper">
      <div className="row justify-content-center">
        <div className="col-12">
          <article>
            <div className="shop-single">
              <div className="row justify-content-center">
                <div className="col-md-4 col-12">
                  <div className="swiper-container gallery-top">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="shop-item">
                          <div className="shop-thumb">
                            <img
                              src="assets/images/banner/puffpastry.jpg"
                              alt="Limoncello Mascarpone Cake"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 col-12">
                  <div className="shop-single-content">
                    <h4>Get Your Mix & Match Puff Pastry Bucket </h4>
                    <div className="desc">
                      <div>
                        <p className="ppdfs">Welcome to 'Breaddough Hot & Fresh,' your gateway to Afghan culinary delights in New York. Our Puff Pastry Assortment Bucket offer lets you craft a personalized experience, choosing from a delectable range of sweet and savory pastries. With over four years of proudly serving the community, our commitment to quality and tradition shines through in every bite. Whether it's the flaky layers of a baklava-inspired pastry or the savory perfection of stuffed bolani, you're in control of your taste journey. At our bakery, we're not just about food; we're a celebration of Afghan culture. Join us on this flavorful adventure that will leave you craving more.</p>
                      </div>
                    </div>
                  </div>
                  <div className="popular-chef-widget">
                    <div className="food-quyality">
                      <div className="section-wrapper">
                        <h4>$13.99/lb</h4>
                        <a
                          href="javascript:void(0)"
                          className="food-btn style-2"
                        >
                          <span>Add to Cart</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</div>
      <div className="shop-page single padding-tb bg-fa">
  <div className="container">
    <div className="section-wrapper">
      <div className="shop-title d-flex flex-wrap justify-content-between">
        <p className="pd-tl">Product Category</p>
      </div>
      <div className="shop-product-wrap grid row">
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="    product-thumb bgm">
              <img
                className="prd-main-img"
                src="https://breaddough.kdassociate.us/public/upload/profile/1699873334jalebi.jpg"
                alt="Jalebi"
              />
         
            </div>
            <div className="product-content">
              <div className="product-title ttt">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/shop/jalebi">Jalebi</a>
                </h6>
                   
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="    product-thumb bgm">
              <img
                className="prd-main-img"
                src="https://breaddough.kdassociate.us/public/upload/profile/1699873294halwaie.jpg"
                alt="Halwaie"
              />
          
            </div>
            <div className="product-content">
              <div className="product-title ttt">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/shop/halwaie">Halwaie</a>
                </h6>
                   
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="    product-thumb bgm">
              <img
                className="prd-main-img"
                src="https://breaddough.kdassociate.us/public/upload/profile/1699873285waraqi.jpg"
                alt="Waraqi 7 Layered Pastry"
              />
             
            </div>
            <div className="product-content">
              <div className="product-title ttt">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/shop/waraqi-7-layered-pastry">
                    Waraqi 7 Layered Pastry
                  </a>
                </h6>
                   
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="    product-thumb bgm">
              <img
                className="prd-main-img"
                src="https://breaddough.kdassociate.us/public/upload/profile/1699873275pechaq.jpg"
                alt="Pechaq"
              />
          
            </div>
            <div className="product-content">
              <div className="product-title ttt">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/shop/pechaq">Pechaq</a>
                </h6>
                   
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="    product-thumb bgm">
              <img
                className="prd-main-img"
                src="https://breaddough.kdassociate.us/public/upload/profile/1699873266coconutdelight.jpg"
                alt="Coconut Delight"
              />
           
            </div>
            <div className="product-content">
              <div className="product-title ttt">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/shop/coconut-delight">Coconut Delight</a>
                </h6>
                   
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="    product-thumb bgm">
              <img
                className="prd-main-img"
                src="https://breaddough.kdassociate.us/public/upload/profile/1699873254SEKERPERA PISTACHIOS.jpg"
                alt="Sekerpera Pistachios"
              />
             
            </div>
            <div className="product-content">
              <div className="product-title ttt">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/shop/sekerpera-pistachios">
                    Sekerpera Pistachios
                  </a>
                </h6>
                   
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="    product-thumb bgm">
              <img
                className="prd-main-img"
                src="https://breaddough.kdassociate.us/public/upload/profile/1699873245tulumba.jpg"
                alt="Tulumba"
              />
          
            </div>
            <div className="product-content">
              <div className="product-title ttt">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/shop/tulumba">Tulumba</a>
                </h6>
                   
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-12 scl">
          <div className="product-item">
            <div className="    product-thumb bgm">
              <img
                className="prd-main-img"
                src="https://breaddough.kdassociate.us/public/upload/profile/1699873237SOBIYET (WITH PISTACHIOS).jpg"
                alt="Sobiyet (with Pistachios)"
              />
           
            </div>
            <div className="product-content">
              <div className="product-title ttt">
                <span className="cat-name">Bakery</span>
                <h6>
                  <a href="/shop/sobiyet-with-pistachios">
                    Sobiyet (with Pistachios)
                  </a>
                </h6>
                   
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="paginations">
        <div className="container">
          <div className="row">
            <div className="col-md-2 btn-pag">
              <a href="#" className="food-btn">
                <span>
                  <i className="fa-solid fa-arrow-left" aria-hidden="true" />
                  &nbsp;&nbsp; Previous
                </span>{" "}
              </a>
            </div>
            <div className="col-md-8">
              <ul className="d-flex flex-wrap justify-content-center">
                <li>
                  <a href="#" style={{ color: "red" }}>
                    1
                  </a>
                </li>
                <li>
                  <a href="#">2</a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 btn-pag tn-pag">
              {" "}
              <a href="#" className="food-btn">
                <span>
                  Next&nbsp;&nbsp;{" "}
                  <i className="fa-solid fa-arrow-right" aria-hidden="true" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
}

export default PuffPastry;
