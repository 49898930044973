import react, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Banner() {
    const [slider, setSlider] = useState([]);
    useEffect(() => {
        const fetchSlider = async () => {
            try {
                const response = await fetch("https://admin.breaddoughhotfresh.com/api/slider")
                if (!response.ok) {
                }
                const data = await response.json();
                setSlider(data)

            } catch (error) {
                console.error(error);
            }
        }
        fetchSlider();
    }, [])
    return (
        <>
            <section className="banner-area bg_size ondk" style={{ backgroundImage: "url(assets/images/banner/banermain.jpg)" }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="banner-content text-center">
                       
                        <div className="banner-btn-group">
                        <a class="foods-btn restaurant" href="/shop/restaurant"><span>Restaurant</span></a>  
                        <a class="foods-btn bakery" href="/shop/bakery"><span>Bakery</span></a>                            </div>
                    </div>
                </div>
            </section>
            <div className="nodk">
            <div className="container-fluid banner-1 ">
  <h1>Welcome to Breaddough Hot &amp; Fresh</h1>
  <div className="row">
    <img src="https://breaddoughhotfresh.com/assets/images/a2.jpg" alt="" />
    <img src="https://breaddoughhotfresh.com/assets/images/a3.jpg" alt="" />
  </div>

  <div className="row">
  <Link to="/shop/restaurant" class="foods-btn restaurant2"><span>Restaurant</span></Link>

  </div>
  <div className="row">
  <img src="https://breaddoughhotfresh.com/assets/images/a5.JPG" alt="" />
    <img src="https://breaddoughhotfresh.com/assets/images/a4.jpg" alt="" />
  </div>
</div>

<div className="container banner-2 ">
<Link to="/shop/bakery" class="foods-btn restaurant2"><span>Bakery</span></Link>


</div>
</div>




            <section className="product style-2 padding-tb bg-fa b2">
                <div className="section-header2">
                    {/* <img src="assets/images/header/sc-img.png" alt="sc-img" className="header-img" /> */}
                    {/* <span>Where Tradition Meets Freshness</span> */}

                    <span className="feel">FEEL AT HOME</span>
                    <h2 className="headermiddle cfd">Savor the True Essence of  Authentic Afghan baking & cooking with Breaddough  Hot & Fresh
                    </h2>
                </div>
                <div className="section-header">
                    {/* <img src="assets/images/header/sc-img.png" alt="sc-img" className="header-img" /> */}


                </div>

                <div id="team-section" className="inner">

                    <div className="team-content sdsd">
                        <div className="t-box1">
                            <a href="/shop"><img src="assets/images/Icon1/Order Now.png" /></a>
                            <a href="/shop"><h3>Order Now</h3></a>
                        </div>
                        <div className="t-box2">
                            <a href="upload/menu.pdf" target="_blank"><img href="/shop" src="assets/images/Icon1/Menu.png" /></a>
                            <a href="upload/menu.pdf" target="_blank"><h3>Menu</h3></a>
                        </div>
                        <div className="t-box3">
                            <a href="https://maps.app.goo.gl/cwcBxhemRpaxYfm26" target="_blank"><img src="assets/images/Icon1/Location.png" /></a>
                            <a href="https://maps.app.goo.gl/cwcBxhemRpaxYfm26" target="_blank"><h3>Locations</h3></a>
                        </div>
                        <div className="t-box4">
                            <a href="/joinus"><img src="assets/images/Icon1/Join Us.png" /></a>
                            <a href="/joinus"><h3>Join Us</h3></a>
                        </div>
                        <div className="t-box5">
                            <a href="/contact-us"><img src="assets/images/Icon1/Contact.png" /></a>
                            <a href="/contact-us"><h3>Contact Us</h3></a>
                        </div>
                    </div>
                </div>


            </section >

        </>
    )
}
export default Banner;

