//import Contact from "../data/Contact";
import react, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/homecontext";
import axios from "axios";
function Header({ size, cart, setCart, userId }) {
  //const [price,setPrice] = useState(0);
  const [submenu, setSubMenu] = useState([]);
  const { isLoading, contact, logoData, category } = useContext(AppContext);
  //console.log(cart);
  const { c_number, email, work_hour } = contact;
  const { logo, tag_name } = logoData;

  const handleRemove = (id) => {
    const arr = cart.filter((item) => item.product_id !== id);
    setCart(arr);
    handlePrice();
  };
  const handleChange = (product_id, d = 1) => {
    let ind = 0;
    console.log(product_id);
    cart.forEach((data, index) => {
      if (data.product_id === product_id) ind = index;
    });
    const tempArr = cart;
    tempArr[ind].amount += d;
    if (tempArr[ind].amount === 0) tempArr[ind].amount = 0;
    setCart([...tempArr]);
    handlePrice();
  };
  const handlePrice = () => {
    let ans = 0;
    cart.map((item) => {
      ans += item.amount * parseFloat(item.product_price);
    });
    return ans;
  };

  const fetchsubCategory = async () => {
    try {
      const res = await axios.get(
        "https://admin.breaddoughhotfresh.com/api/productsubcategory/"
      );
      const categoryData = await res.data;
      setSubMenu(categoryData);
    } catch (error) {
      console.log("subcategory error", +error);
    }
  };
  useEffect(() => {
    fetchsubCategory();
  }, []);
  return (
    <div>
 

      <nav className="navbar navbar-expand-lg navbar-light bg-light nnn">
        <a className="navbar-brand" href="/">
        <img src="assets/images/BreadDough.png" alt={tag_name} />

        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
            
              <a className="nav-link" href="/">
              Home <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about-us">
              About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/menumob">
              Menu
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/contact-us">
              Contact Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/login">
              Login
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/signup">
              Sign Up
              </a>
            </li>
         
          </ul>
     
        </div>
      </nav>

      <header className="header-section">
        <div className="header-area">
          <div className="header-top">
            <div className="container">
              <div className="top">
                <ul className="left">
                  <li>
                    <i className="fas fa-phone" />{" "}
                    <a
                      href="tel:{c_number}"
                      target="_blank"
                      style={{ color: "#fff" }}
                    >
                      {" "}
                      {c_number}
                    </a>
                  </li>
                  <li>
                    <i className="fa-regular fa-envelope" />{" "}
                    <a
                      href="mailto:{email}
                "
                      target="_blank"
                      style={{ color: "#fff" }}
                    >
                      {email}
                    </a>
                  </li>
                  <li>
                    <i className="fa-regular fa-clock" />
                    &nbsp; {work_hour}
                  </li>
                </ul>
                <ul className="right">
                  <li>
                    <a href="https://www.facebook.com/people/Breaddough-Hotfresh/100089824064774/">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/breaddough_hotfresh/">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="header-bottom">
            <div className="container">
              <div className="primary-menu">
                <div className="logo">
                  <a href="/">
                  <img src="assets/images/BreadDough.png" alt={tag_name} />   </a>
                </div>
                <div className="main-area">
                  <div className="main-menu">
                    <nav className="navbar navbar-expand-lg navbar-light">
                      {/* Container wrapper */}
                      <div className="container-fluid">
                        {/* Toggle button */}
                        <button
                          className="navbar-toggler px-0"
                          type="button"
                          data-mdb-toggle="collapse"
                          data-mdb-target="#navbarExampleOnHover"
                          aria-controls="navbarExampleOnHover"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <i className="fas fa-bars" />
                        </button>
                        {/* Collapsible wrapper */}
                        <div
                          className="collapse navbar-collapse"
                          id="navbarExampleOnHover"
                        >
                          {/* Left links */}
                          <ul
                            className="navbar-nav me-auto ps-lg-0"
                            style={{ paddingLeft: "0.15rem" }}
                          >
                            <li className="active">
                              <Link to="/">Home</Link>
                            </li>

                            <li>
                              <Link to="/about-us">About Us</Link>
                            </li>

                            <li className="nav-item dropdown dropdown-hover position-static">
                              <a
                                className="nav-link dropdown-toggle"
                                href="/shoap"
                                id="navbarDropdown"
                                role="button"
                                data-mdb-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Menu
                              </a>
                              <div
                                className="dropdown-menu w-100 mt-0"
                                aria-labelledby="navbarDropdown"
                                style={{
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                }}
                              >
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
                                      <div className="list-group list-group-flush">
                                        <Link to="/shop/restaurant"><p className="mb-0 list-group-item text-uppercase font-weight-bold">
                                            Restaurant
                                          </p></Link>
                                         
                                        <Link to="/shop/restaurant/wraps-and-subs" className="list-group-item list-group-item-action">
                                        
                                         
                                          <img
                                            src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701072158Wraps.png"
                                            alt=""
                                          />
                                          Wraps & Subs
                                        </Link>
<Link to="/shop/restaurant/Vegetarian" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701072158Wraps.png" alt=""/>
vegetarian
</Link>

<Link to="/shop/restaurant/meaty" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701334503Meaty.png" alt=""/>
Meaty
</Link>
<Link to="/shop/restaurant/kabobs" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701334536Kabobs.png" alt=""/>
Kabobs
</Link>
                                         
                                        
                                         
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
                                      <div className="list-group list-group-flush">
                                       <Link to="/shop/bakery"><p className="mb-0 list-group-item text-uppercase font-weight-bold">
                                            Bakery
                                          </p></Link>
                                          <Link to="/shop/bakery/bread" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701642265Naan.png" alt=""/>
Bread</Link>
<Link to="/cookies" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701335414Afghani-Kulcha-(cookies-with-nuts.png" alt=""/>
Cookies
</Link>
<Link to="/puffpastry" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701335546Puff-Pastry.jpg.png" alt=""/>
Puff Pastry
</Link>
<Link to="/shop/bakery/cake" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701335619Cake.png" alt=""/>
Cake
</Link>

               
                                         
                                        
                                        
                                         
                                        
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
                                      <div className="list-group list-group-flush">
                                       <Link to="/shop/beverages"><p className="mb-0 list-group-item text-uppercase font-weight-bold">
                                            Beverages
                                          </p></Link>
  
<Link to="/shop/beverages/smoothies" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701336030Smoothies.png" alt=""/>
Smoothies</Link>
<Link to="/shop/beverages/soda" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701336205Soda.png" alt=""/>
Soda</Link>
<Link to="/shop/beverages/energy-drinks" className="list-group-item list-group-item-action">
<img src="https://admin.breaddoughhotfresh.com/public/upload/profile/1701336278Energy-Drinks.png" alt=""/>
Energy Drinks</Link>
                                        
                                         
                                        
                                       
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>

                            <li>
                              <Link to="/blog">Blog</Link>
                            </li>

                            <li>
                              <Link to="/contact-us">Contact Us</Link>
                            </li>
                          </ul>
                          {/* Left links */}
                        </div>
                        {/* Collapsible wrapper */}
                      </div>
                      {/* Container wrapper */}
                    </nav>
                  </div>
                </div>

                <div className="cart-search">
                  <ul>
                    <li className="cart-area">
                      <div className="cart-icon">
                        <i className="icofont-cart-alt" />
                      </div>
                      <div className="count-item">{size}</div>
                      <div className="cart-content">
                        <div className="cart-title">
                          <div className="add-item">{size} Items Added</div>
                          <div className="list-close">
                            <a href="#">Close</a>
                          </div>
                        </div>
                        <div className="cart-scr scrollbar">
                          <div className="cart-con-item">
                            {cart &&
                              cart.map((data) => {
                                const {
                                  product_id,
                                  product_code,
                                  title,
                                  product_price,
                                  amount,
                                  photo,
                                  cname,
                                  sname,
                                } = data;
                                // p = p + parseFloat(product_price);
                                return (
                                  <div className="cart-item">
                                    <div className="cart-inner">
                                      <div className="cart-top">
                                        <div className="thumb">
                                          <a href="#">
                                            <img src={photo} alt={title} />
                                          </a>
                                        </div>
                                        <div className="content">
                                          <a href="#">{title}</a>
                                        </div>
                                        <div className="remove-btn">
                                          <a
                                            href="javascript:void(0)"
                                            onClick={() =>
                                              handleRemove(product_id)
                                            }
                                          >
                                            <i className="icofont-close" />
                                          </a>
                                        </div>
                                      </div>
                                      <div className="cart-bottom">
                                        <div className="sing-price">
                                          Tk. {product_price}
                                        </div>
                                        <div className="cart-plus-minus">
                                          <div
                                            className="dec qtybutton"
                                            onClick={() =>
                                              handleChange(product_id, -1)
                                            }
                                          >
                                            -
                                          </div>
                                          <div
                                            className="dec qtybutton"
                                            onClick={() =>
                                              handleChange(product_id, -1)
                                            }
                                          >
                                            -
                                          </div>
                                          {amount}
                                          <div
                                            className="inc qtybutton"
                                            onClick={() =>
                                              handleChange(product_id, +1)
                                            }
                                          >
                                            +
                                          </div>
                                          <div
                                            className="inc qtybutton"
                                            onClick={() =>
                                              handleChange(product_id, +1)
                                            }
                                          >
                                            +
                                          </div>
                                        </div>
                                        <div className="total-price">
                                          Tk.
                                          {amount * parseFloat(product_price)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                        <div className="cart-scr-bottom">
                          <ul>
                            <li>
                              <div className="title">Subtotal</div>
                              <div className="price">Tk. {handlePrice()}</div>
                            </li>
                            <li>
                              <div className="title">Shipping</div>
                              <div className="price">Tk.0.00</div>
                            </li>
                            <li>
                              <div className="title">Cart Total</div>
                              <div className="price">Tk. {handlePrice()}</div>
                            </li>
                          </ul>
                          {size > 0 && (
                            <div className="list-close">
                              <a href="#">
                                <Link to="/checkout" className="food-btn">
                                  <span>Place Order</span>
                                </Link>

                                <Link to="/cart" className="food-btn">
                                  <span>Cart</span>
                                </Link>
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                    {userId === 0 && (
                      <li className="login">
                        <Link to="/login" class="foods-btn style-btn lgr">
                       
                            Login/Register
                      
                        </Link>
                      </li>
                    )}
                    {userId > 0 && (
                      <>
                        <li className="login">
                          <Link to="/myaccount">
                            <i className="icofont-ui-user" />
                          </Link>
                        </li>
                        <li className="login">
                          <Link to="/logout">
                            <i class="fa-solid fa-arrow-right-from-bracket"></i>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
